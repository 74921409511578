import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

function withMyHook(Component) {
    return function WrappedComponent(props) {
      const myHookValue = useAuth0();
      return <Component {...props} myHookValue={myHookValue} />;
    }
  } 

export default withMyHook;