import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import CornerstoneElement from '../diacom_viewer_component/dicom_viewer.component'
// import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
// import '../../assets/upload.css'
//  const fs = window.require("fs")
//  const path = window.require('path');

class DicomContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show:true,
            stack: {
                imageIds: [],
                currentImageIdIndex: 0
            },
            error: '',
            loading: false,
            toasts: []
        };
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.id !== prevProps.id) {
            this.setState({
                        show:true,
                        stack: {
                            imageIds: [],
                            currentImageIdIndex: 0
                        },
                        error: '',
                        loading: false,
                        toasts: []
                    })
            let that = this;
            // setTimeout(function(){ that.getFileFromLocalWithFs() }, 1000);
            
        }
    }

    handleShow = () => {
        this.setState({show:true})
    }

    handleClose = () => {
        this.setState({show:false})
    }


    componentDidMount() {
 
        let notifyNotFoundDiv = document.getElementById('notifyFileNotFound')
        notifyNotFoundDiv.style.display = "none"
          this.getFileFromGit();
    }

    getFileFromGit() {
        
        this.setState({
            ...this.state,
            loading: true
        })
        const imageId=this.props.file_name
       
        this.setState({
            ...this.state,
            loading: false,
            stack: {
                imageIds:[`dicomweb:${imageId}`],
                
                currentImageIdIndex: 0
            }
        })
    }

   
    setToast = (_title, _message, _type, _delay = 3000) => {
        this.setState({
            ...this.state,
            toasts : [{ id: this.getRandumNumber(), 'title': _title, 'message': _message, 'type': _type, delay: _delay} ]
        })
    }

    getError = (error) => {
        return error?.response?.data?.error?.message ||  error?.message || 'Unknown Error';
    }

    getRandumNumber = () => {
        return Math.floor(Math.random() * 100000)
    }

    render() {
        
        return (
            <Modal show={this.state.show} onHide={this.handleClose} dialogClassName="my-modal2"
            // 

            // .my-modal2 {
            //     width: 60vw; /* Occupy the 90% of the screen width */
            //     max-width: 60vw;
            //     height:"512px";
            //     color: "white"
            //   }
                 aria-labelledby="contained-modal-title-vcenter" backdrop="static"
            centered>
                <Modal.Header closeButton>
                   {/* <h5> {this.props.data.file}</h5> */}
                  {this.state.loading === true && <span> Loading...</span>} 
                  {/* <Modal.Title>{path.basename(this.props.file_name)}</Modal.Title> */}
                  
                </Modal.Header>
                
                <Modal.Body style={{'minHeight':'600px','overflow': 'scroll'}} id="modBody">
                {/* <Modal.Body> */}
                    <label id="notifyFileNotFound" style={{display:"none",fontWeight:'normal',alignItems:'center'}}>This file cant be displayed. This file doesn't exist on the path specified. </label>
                    {
                    this.state.stack.imageIds.length > 0 && <CornerstoneElement stack={{...this.state.stack} } />
                    }
                </Modal.Body>
            </Modal>
        )
    }
}

export default DicomContainer;
