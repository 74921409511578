import React, { Component, useState } from "react";
import ReactDOM from "react-dom";
import ReactDataGrid from "react-data-grid";
import { Toolbar, Data, Filters, Editors } from "react-data-grid-addons";
import ReactPaginate from 'react-paginate';
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from '@material-ui/core/Checkbox';
import Form from 'react-bootstrap/Form'
import { Row, Col } from 'react-bootstrap';
import BasicLayout from '../../routes/basicLayout'
import { Link } from 'react-router-dom';
import DiacomContainer from "../../components/diacomContainer/dicomModalUpdated"
import LoadingOverlay from 'react-loading-overlay';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import BoostrapModalComponent from '../../components/modalComponent/bootstrap_modal.component'
import createNotification from "../../components/notification/notificationComponent"
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from 'react-bootstrap/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Cookies from 'js-cookie'
import { FaGalacticSenate ,BsFillInfoCircleFill} from "react-icons/fa";
import Icon from '@material-ui/core/Icon';
import axios from "axios";
import Tooltip from '@material-ui/core/Tooltip';
import withMyHook from "./hookWrapper"
import Popover from '@material-ui/core/Popover';
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import { EVENTS, MODALITY_TASK } from "../../common/constants";
import FileApi from '../../api/file.api';
import eventBus from "../../common/eventBus";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',

    // backgroundColor:"#374f65"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));
const CVATLINK =process.env.REACT_APP_CVAT
const URL = process.env.REACT_APP_BACKEND
const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE || 10
console.log('Page Size - ' + PAGE_SIZE);

class ReactDataGridComponent extends Component {

  constructor(props) {
    super(props);
    this.file_api = new FileApi();
    this.total_row_count = 0;
    this.current_page = 0;
    this.selected_modality = MODALITY_TASK.DEFAULT();
    this.filteredColumns = this.getFilteredColumns();
    const show_validate = this.canShowValidate();
    this.state = {
      filters: {},
      rows: [],
      selectedIndexes: [],
      selectedID: [],
      loader: false,
      open: null,
      threshold: '',
      column: '',
      max_column_name: {},
      max_column_value: {},
      id: '',
      checkallcheckbox: false,
      checksinglecheckbox: false,
      file_name: null,
      max_value: null,
      max_value_column: null,
      modified_on: null,
      processing_stage: null,
      created_on: null,
      filtersApplied: false,
      thresholdFilterApplied:false,
      sortColumn: null,
      sortDirection: null,
      show_threshold_filter_view: !show_validate,
      show_predicted_view: !show_validate,
      show_classify: !show_validate,
      show_validate: show_validate,
      current_page: 2
    }
  }

  canShowValidate = () => {
    return (MODALITY_TASK.ECG_Classification == this.selected_modality);
  }

  onModalitySelected = async (selected_modality) => {
    this.selected_modality = selected_modality;
    this.current_page = 0;
    this.resetFilters();
    this.clearSelectFilters();
    this.filteredColumns = this.getFilteredColumns(selected_modality);
    await this.loadFiles();
    const show_validate = this.canShowValidate();
    this.setState({
      show_classify: !show_validate,
      show_validate: show_validate,
      show_predicted_view: !show_validate
    });
  }

  getFilteredColumns = () => {
    return this.columns.filter((column) => {
      return !column.display_on || (column.display_on.indexOf(this.selected_modality) > -1);
    }).map(c => ({ ...c, ...this.defaultColumnProperties }));
  }

  defaultColumnProperties = {
    width: 178
  };

  async componentDidMount() {
    eventBus.on(EVENTS.MODALITY_SELECTED, this.onModalitySelected);
    setTimeout(async () => {
      this.setState({ loader: true })
      await this.loadFiles();
    }, 500);
    this.autoReload();
  }

  async loadFiles({page_number=null, sort=false, error_msg=null, bg=false} = {}) {
    if (!bg) this.setState({ loader: true });
    try {
      let files;
      if ([MODALITY_TASK.Echo_Segmentation, MODALITY_TASK.Echo_Classification].indexOf(this.selected_modality) > -1) {
        files = await this.file_api.getFiles({
          page: page_number,
          file_type: 'echo',
          throw_on_error:(error_msg != null)
        });
      } else if (this.selected_modality == MODALITY_TASK.ECG_Classification) {
        files = await this.file_api.getFiles({
          page: page_number,
          file_type: 'ecg',
          throw_on_error:(error_msg != null)
        });
      }
      if (files && files.results) {
        this.total_row_count = files.count;
        if (sort) {
          this.setState({ rows: this.sortRows(files.results, this.state.sortColumn, this.state.sortDirection) });
        } else {
          this.setState({ rows: files.results, selectedIndexes: [] });
        }
      }
    } catch (e) {
      if (error_msg != null) {
        createNotification('error', error_msg);
      } else {
        createNotification('error', 'Something went wrong.');
      }
    } finally {
      this.setState({ loader: false });
    }
  }

  async loadThresholdFiles({sort=false, bg=false} = {}) {
    if (!bg) this.setState({ loader: true })
    const files = await this.file_api.getClassificationByThreshold({
      "threshold_value": this.state.threshold,
      "column": this.state.column.toString()
    });
    this.setState({ loader: false })
    if (files) {
      if (sort) {
        this.setState({
          rows: this.sortRows(files, this.state.sortColumn, this.state.sortDirection)
        });
      } else {
        this.setState({ rows: files });
      }
    }
  }

  autoReload = () => {
    try {
      setInterval(async () => {
        if (!this.state.filtersApplied && !this.state.thresholdFilterApplied) {
          this.loadFiles({sort: true, bg: true});
        } else if (this.state.thresholdFilterApplied) {
          this.loadThresholdFiles({sort:true, bg: true});
        } else {
          let processingStage = this.state.processing_stage != null
            ? this.state.processing_stage.toString().toUpperCase()
            : this.state.processing_stage;       
          const files = await this.file_api.getFilteredData({
              "file_id_value": "",
              "file_name_value": this.state.file_name,
              "file_extention": (this.selected_modality == MODALITY_TASK.ECG_Classification) ? '.xml' : '.dcm',
              "max_value_value": this.state.max_value,
              "max_value_column_value": this.state.max_value_column,
              "modified_on_value": Date.parse(this.state.modified_on)/1000,
              "processing_stage_value": processingStage,
              "created_on_value": Date.parse(this.state.created_on)/1000
            }
          );
          if (files) {
            this.setState({
              rows: this.sortRows(files, this.state.sortColumn, this.state.sortDirection)
            });
          }
        }
      }, 30000);
    } catch (e) {
    }
  }

  handleChange = (event) => {
    // setAge(Number(event.target.value) || '');
    this.setState({ column: (event.target.value) })
  };

  handleThresholdChange = (event) => {
    // setAge(Number(event.target.value) || '');
    this.setState({ threshold: (event.target.value) })
  };

  clearSelectFilters() {
    this.setState({
      threshold: '',
      column: '',
      thresholdFilterApplied: false
    });
  }

  clearSelect = async () => {
    this.clearSelectFilters();
    this.loadFiles();
  }

  redirectToAnnotation=()=>{
    window.open(`${CVATLINK}/tasks`, "_parent");
  }

  applyThreshold = async () => {
    let { column, threshold } = this.state;
    this.setState({thresholdFilterApplied:true, filtersApplied: false});
    this.setState({
      file_name: "",
      max_value: "",
      max_value_column: "",
      modified_on: "",
      processing_stage: "",
      created_on: "",
    });
    this.loadThresholdFiles();
  }

  handleClickOpen = (file_name) => {
    this.setState({ open: true})
    return (

      <Popover
        open={true}
         anchorEl={true}
        onClose={this.handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography > {file_name}</Typography>
      </Popover>
                
    )
  };

  handleClose = () => {
    this.setState({ open: false })
  };

  ProgressBarFormatter = ({ value }) => {

    // return <a href="javascript:void(0);" onClick={(e) => viewdiacom(value,e)}>{value}</a> ;
    return <Link onClick={() => this.viewdiacom(value)} >{value}</Link>
    //  <Button onClick={() => viewdiacom(value)}> {value}</Button>
  };

  FileIdFormatter = ({ row }) => {

    return (
      <div >
        <div style={{ overflow: "hidden", textOverflow: "ellipsis",float: "left", width: "80%" }}>
        <a href="javascript:void(0);" onClick={() => this.showFileData(row.id)}>
          {row.file_name}
        </a>
        </div>
        <div>
          <Tooltip title={<Typography color="inherit"> {row.file_name}</Typography>} interactive>
            <InfoIcon />
          </Tooltip>
        </div>
      </div>
    )
  };

  maxValueFormatter = ({ value }) => {
    if (value != null || value != undefined) {
      const period=4
      // return (value.toFixed(3))
      if(value != null || value != undefined) {
        let numToStr = value.toString();
        let contains_period = numToStr.search(".")
        if(contains_period == 0) {
          let position_period = numToStr.lastIndexOf(".")
          let totallength = numToStr.length;
          return numToStr.substring(0,position_period + period)
        } else {
          return numToStr;
        }
      }
      return ""
    } else {
      return ("No Data Found ")
    }
  }

  // getDeltaXformat(value,period)
  // {

  // }

  maxColumnFormatter = ({ value }) => {
    if (value != null || value != undefined) {
      return (value)
    } else {
      return ("No Data Found ")
    }
  }

  DateFormatter = ({ value }) => {
    var datee = new Date(value * 1000)
    var date = datee.toLocaleString('en-US');
    return date
  };

  checkboxFormatter = ({ row }) => {
    return <Checkbox
      id={row.id}
      color="primary"
      inputProps={{ 'aria-label': 'controlled' }}
      onClick={() =>
        this.state.selectedID.includes(row.id) ? this.onRowDeselected(row) : this.onRowSelected(row)
      }
      checked={
        this.state.selectedID.includes(row.id)
      }
    />
  }

  viewdiacom = (value, e) => {
    ReactDOM.render(<DiacomContainer file_name={value} filePath={value} fileType={value} id={Date.now()} />,
      document.getElementById('modal_div'))
  }

  showFileData = async (value) => {
    let accessToken = sessionStorage.getItem("Auth0token");
    this.setState({ loader: true })
    const file_data = await this.file_api.getFiles({file_type: value});
    if (file_data) {
      this.setState({ loader: false })
      return (
        ReactDOM.render(
          <BoostrapModalComponent
            data={file_data}
            modal_type="study_grid"
            // show_modal={true}
            id={Date.now()}
          />,
          document.getElementById("modal_div")
        )
      )
    }
  }

  refresh = async () => {
    const show_validate = this.canShowValidate();
    this.setState({
      file_name: "",
      max_value: "",
      max_value_column: "",
      modified_on: "",
      processing_stage: "",
      created_on: "",
      threshold: '', 
      column: '',
      thresholdFilterApplied:false, 
      filtersApplied: false,
      show_predicted_view: !show_validate,
      show_classify: !show_validate,
      show_validate: show_validate
    })
    this.loadFiles({error_msg: "Something went wrong"});
  }

  handleFilterChangeState = (event, colName) => {

    if (['modified_on', 'created_on'].indexOf(colName) > -1) return;
    this.setState({ [colName]: (event.target.value) });
  }

  handleDateChange = (event, colName) => {
    if (['modified_on', 'created_on'].indexOf(colName) == -1) return;
    this.setState({ [colName]: (event.target.value) });
  }

  classificationKey = [
    "a2c",
    "a2c_laocc",
    "a2c_lvocc_s",
    "a3c",
    "a3c_laocc",
    "a3c_lvocc_s",
    "a4c",
    "a4c_far",
    "a4c_laocc",
    "a4c_lvocc_s",
    "a4c_rv",
    "a4c_rv_laocc",
    "a5c",
    "apex",
    "other",
    "plax_far",
    "plax_lac",
    "plax_laz",
    "plax_laz_ao",
    "plax_plax",
    "psax_avz",
    "psax_az",
    "psax_mv",
    "psax_pap",
    "rvinf",
    "subcostal",
    "suprasternal"
  ]

  selectors = Data.Selectors;

  columns = [
    // {
    //   name:<Checkbox   inputProps={{ 'aria-label': 'controlled' }} 
    //   color="primary"
    //   onClick={() => this.state.checkallcheckbox == false?
    //     this.onRowsSelected(this.props.rows):
    //     this.onRowsDeselected(this.props.rows)
    //   }
    //   />,
    //    formatter: this.checkboxFormatter,
    //   sortable : false,
    //   filterable: false

    // },
    {
      key: "file_name",
      name: "File Name",
      formatter: this.FileIdFormatter,
      filterable: true,
      sortable: true,
      // editable: true



    }, {
      key: "max_value_column",
      name: <Tooltip title={<h7>Values will be filled after view classification is performed.</h7>}
      arrow>
  <b>Predicted View</b>
    </Tooltip>,
      formatter: this.maxColumnFormatter,
      filterable: true,
      sortable: true,
      display_on: [MODALITY_TASK.Echo_Classification, MODALITY_TASK.Echo_Segmentation]
    },
    {
      key: "max_value",
      name:
      <Tooltip title="Values will be filled after view classification is performed." arrow>
        <b>Probability of Predicted View</b>
      </Tooltip>,
      formatter: this.maxValueFormatter,
      filterable: true,
      sortable: true,
      display_on: [MODALITY_TASK.Echo_Classification, MODALITY_TASK.Echo_Segmentation]
    },
    {
      key: "processing_stage",
      name: "Processing Stage",
      filterable: true,
      sortable: true,
      display_on: [MODALITY_TASK.Echo_Classification, MODALITY_TASK.Echo_Segmentation]
    },
    {
      key: "condition",
      name: "Condition",
      filterable: true,
      sortable: true,
      display_on: [MODALITY_TASK.Echo_Classification, MODALITY_TASK.ECG_Classification]
    },
    {
      key: "processing_stage",
      name: "Validation Stage",
      filterable: true,
      sortable: true,
      display_on: [MODALITY_TASK.ECG_Classification]
    },
    {
      key: "frame_time",
      name: "Sampling Rate",
      filterable: true,
      sortable: true,
      display_on: [MODALITY_TASK.ECG_Classification]
    },
    {
      key: "created_on",
      name: "Date of Task Creation",
      formatter: this.DateFormatter,
      filterable: true,
      sortable: true,
    },

    {
      key: "modified_on",
      name: "Modified On",
      formatter: this.DateFormatter,
      filterable: true,
      sortable: true,
    },
  ];

  getRows(rows, filters, row) {
    // const row = Object.assign({}, rows, {nameandpic});
    return this.selectors.getRows({ rows, filters, row });
  }

  onRowSelected = (row) => {
    this.setState({
      selectedID: this.state.selectedID.concat(row.id),
      selectedIndexes: this.state.selectedIndexes.concat(row.rowIdx),
    });
  };

  // onRowsSelected = (rows) => {
  //       this.setState({
  //         selectedIndexes: this.state.selectedIndexes.concat(
  //           rows.map((r) => r.rowIdx
  //           )
  //         ),
  //         selectedID: this.state.selectedID.concat(
  //           rows.map((r) => r.id)
  //         ),
  //         checkallcheckbox: !this.state.checkallcheckbox
  //       });

  //     };

  onRowsSelected = (rows) => {
    let accessToken = sessionStorage.getItem("Auth0token");
    rows.map((row) => {
      if (row.row.processing_stage == "DICOM_TO_NPY_ARRAY" || 
        row.row.processing_stage == "PROCESSING") {
        createNotification('error', "Files are already in processing ")
      } else {
        this.setState({
          selectedIndexes: this.state.selectedIndexes.concat(
            rows.map((r) => r.rowIdx)
          ),
          selectedID: this.state.selectedID.concat(
            rows.map((r) => r.row.id)
          )
        });
      }
    });
  };

  EmptyRowsView = () => {
    const message = "No data to show";
    return (
      <div
        style={{ textAlign: "center", padding: "100px", backgroundColor: "#ddd" }}
      >
        <h3>No Records Found</h3>
      </div>
    );
  };

  // onRowsDeselected = (rows) => {
  //       let rowIndexes = rows.map((r) => r.rowIdx);
  //       let rowId = rows.map((r) => r.id)
  //       this.setState({
  //         selectedID: this.state.selectedID.filter(
  //           (i) => rowId === -1
  //         )
  //       });
  //       this.setState({
  //         selectedIndexes: this.state.selectedIndexes.filter(
  //           (i) => rowIndexes === -1
  //         )
  //       });
  //       this.setState({checkallcheckbox: false})
  //     };

  // onRowDeselected = (row) => {

  //       let rowIndexes = row.rowIdx;
  //       let rowId = row.id
  //       this.setState({
  //         selectedID: this.state.selectedID.filter(
  //            (i) => rowId !=i
  //           //  rowId 
  //         )
  //       });
  //       this.setState({
  //         selectedIndexes: this.state.selectedIndexes.filter(
  //           (i) => rowIndexes != i
  //           // (i) => rowIndexes.indexOf(i) === -1
  //         )
  //       });
  //     };

  onRowsDeselected = (rows) => {
    let rowIndexes = rows.map((r) => r.rowIdx);
    let rowId = rows.map((r) => r.row.id)
    this.setState({
      selectedID: this.state.selectedID.filter(
        (i) => rowId.indexOf(i) === -1
      )
    });
    this.setState({
      selectedIndexes: this.state.selectedIndexes.filter(
        (i) => rowIndexes.indexOf(i) === -1
      )
    });
  };

  annotationJob = async () => {
    let accessToken = sessionStorage.getItem("Auth0token");
    if (this.state.selectedIndexes.length == 0) {
      return createNotification('error', "Please select file to Annotate")
    }
    try {
      this.setState({ loader: true })
      const files = await this.file_api.createAnnotationTask(
        { "file_ids": this.state.selectedID },
        { throw_on_error: true }
      );
      if (files) {
        this.setState({ loader: false, selectedIndexes: [], selectedID: [] })
        createNotification('success', "Annotation Task created successfully");
        setTimeout(function(){  window.open(`${CVATLINK}`, "_blank") }, 3000);
      }
    } catch (e) {
      createNotification('error', "Something went wrong")
      this.setState({ loader: false, selectedIndexes: [], selectedID: [] })
    }
  }

  deleteFile = async () => {
    if (this.state.selectedIndexes.length == 0) {
      return createNotification('error', "Please select file to delete");
    }
    this.setState({ loader: true });
    try {
      await this.file_api.deleteFiles(
        { "file_ids": this.state.selectedID },
        { throw_on_error: true }
      );
      createNotification('success', "Record deleted successfully");
      this.loadFiles({error_msg: "Something went wrong"});
    } catch (e) {
      createNotification('error', "Something went wrong")
    } finally {
      this.setState({ loader: false, selectedIndexes: [], selectedID: [] })
    }
  }

  viewClassifyFile = async () => {
    try {
      this.setState({ loader: true })
      const result = await this.file_api.getClassificationProcessorUploadStatus(
        { "file_ids": this.state.selectedID },
        { throw_on_error: true }
      );
      this.setState({ loader: false })
      if (result && result.instance_files) {
        createNotification('success', "Queued for View Classification");
        this.setState({ selectedIndexes: [], selectedID: [] });
      } else if (json && json.message) {
        createNotification('error', json.message);
      } else {
        createNotification('error', "Something went wrong");
      }
    } catch (e) {
      createNotification('error', "Something went wrong")
      this.setState({ loader: false, selectedIndexes: [], selectedID: [] })
    }
  }

  handleFilterChange = async (filter) => {
    let cancelToken;
    this.setState({ filtersApplied: true, thresholdFilterApplied: false });
    this.setState({ threshold: '', column: ''});
    this.setState({ filter: filter });
    const newFilters = { ...this.state.filters };
    console.log("Canceltoken", typeof cancelToken)
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Canceling previous request")
    }
    cancelToken = axios.CancelToken.source()
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
      const files = await this.file_api.getFilteredData({
        "column": filter.column.key,
        "column_value": filter.filterTerm
      });
      if (files) {
        this.setState({ rows: files });
      }
    }
  }

  resetFilters() {
    const show_validate = this.canShowValidate();
    this.setState({
      loader: true,
      file_name: "",
      max_value: "",
      max_value_column: "",
      modified_on: "",
      processing_stage: "",
      created_on: "",
      filtersApplied: false,
      show_predicted_view: !show_validate,
      show_classify: !show_validate,
      show_validate: show_validate
    })
  }

  clearFilter = async () => {
    this.resetFilters();
    this.loadFiles({error_msg: "Something went wrong"});
  }

  applyFilersonGrid = async () => {
    let processingStage = this.state.processing_stage != null
      ? this.state.processing_stage.toString().toUpperCase()
      : this.state.processing_stage;
    this.setState({ filtersApplied: true, thresholdFilterApplied: false })
    this.setState({ threshold: '', column: ''})
    this.setState({ loader: true })
    const files = await this.file_api.getFilteredData({
        "file_id_value": "",
        "file_name_value": this.state.file_name,
        "file_extention": (this.selected_modality == MODALITY_TASK.ECG_Classification) ? '.xml' : '.dcm',
        "max_value_value": this.state.max_value,
        "max_value_column_value": this.state.max_value_column,
        "modified_on_value": Date.parse(this.state.modified_on)/1000,
        "processing_stage_value": processingStage,
        "created_on_value": Date.parse(this.state.created_on)/1000
      },
      { throw_on_error: true }
    );
    this.setState({ loader: false });
    if (files) {
      this.setState({ rows: files });
    }
  }

  sortRows = (initialRows, sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    return sortDirection === "NONE" ? initialRows : [...this.state.rows].sort(comparer);
  };

  handlePageChange = (page) => {
    this.current_page = page.selected;
    this.loadFiles({page_number: page.selected+1});
  }

  render() {

    // let keys = this.state.rows.length !=0  ?Object.keys(this.state.rows &&this.state.rows[0].view_classification_result) :null
    const rowText = this.state.selectedIndexes.length === 1 ? "row" : "rows";
    this.filteredRows = this.getRows(this.state.rows, this.state.filters);
    const RowRenderer = ({ row, idx }) => {
      const { presigned_url } = row;
      return (
        <a href={row.presigned_url}>{presigned_url}</a>
      );
    };

    return (
      <div>
        <NotificationContainer />
        <div>
          <div id="modal_div"> </div>
          {this.state.show_predicted_view && (<form className="container" style={{ marginBottom: "10px", marginTop: "10px", backgroundColor: "white", paddingTop: "10px", paddingBottom: "10px" }} >
            <FormControl className="formControl" style={{ marginLeft: "10px", marginTop: "-10px", minWidth: 150 }} >
              <InputLabel htmlFor="demo-dialog-native">View name</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={this.state.column}
                onChange={(event) => this.handleChange(event)}
                input={<Input />}
              >
                {
                  this.classificationKey.map((key) => {
                    return (
                      <MenuItem value={key}>
                        <em>{key}</em>
                      </MenuItem>)
                  })
                }
              </Select>
            </FormControl>
            <FormControl className="formControl" style={{ marginLeft: "20px", marginTop: "-10px", minWidth: 100 }}>
              <TextField id="standard-basic" label="Threshold value" onChange={(event) => this.handleThresholdChange(event)} value={this.state.threshold} />
            </FormControl>
            <Button onClick={() => this.applyThreshold()}
              style={{ marginLeft: "20px" }} disabled={this.state.column == '' || this.state.threshold == ''}>Apply
            </Button>
            <Button onClick={() => this.clearSelect()}
              disabled={this.state.column == '' && this.state.threshold == ''}
              style={{ marginLeft: "20px" }}>Clear</Button>
            {/* <Button onClick={() => this.redirectToAnnotation()} style={{ marginLeft: "80px" }}> CVAT</Button> */}
          </form>)}
          <form style={{ marginBottom: "10px", marginTop: "10px", backgroundColor: "white", paddingTop: "10px", paddingBottom: "10px" }} >
            <FormControl className="formControl" style={{  marginTop: "-10px", minWidth: 100 }}>
              <TextField id="standard-basic" label="File Name" onChange={(event) => this.handleFilterChangeState(event, "file_name")} value={this.state.file_name} />
            </FormControl>
            {this.state.show_predicted_view && (<FormControl className="formControl" style={{ marginLeft: "20px", marginTop: "-10px", minWidth: 150 }} >
              <InputLabel htmlFor="demo-dialog-native">Predicted view</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={this.state.max_value_column}
                onChange={(event) => this.handleFilterChangeState(event, "max_value_column")}
                input={<Input />}
              >
                {
                  this.classificationKey.map((key) => {
                    return (
                      <MenuItem value={key}>
                        <em>{key}</em>
                      </MenuItem>)
                  })
                }
              </Select>
            </FormControl>)}
            <Button onClick={() => this.applyFilersonGrid()}
              style={{ marginLeft: "20px", marginTop: "10px" }}
            >Apply
            </Button>
            <Button onClick={() => this.clearFilter()}
              style={{ marginLeft: "20px", marginTop: "10px" }}>Clear</Button>
          </form>
          <LoadingOverlay
            active={this.state.loader}
            spinner
            text='Loading...'
          >
            <ReactDataGrid
              rowKey="id"
              columns={this.filteredColumns}
              rowGetter={i => this.filteredRows[i]}
              rowsCount={this.filteredRows.length}
              emptyRowsView={this.EmptyRowsView}
              minHeight={420}
              enableCellSelect= {true}
              // onCellSelected={s => this.setSelection({topLeft: s, bottomRight: s})}
                            toolbar={
                <Toolbar enableFilter={false}>
                  <Button onClick={() => this.deleteFile()} style={{ marginLeft: "20px" }}>  Delete </Button>
                  { this.state.show_classify && (<Button onClick={() => this.viewClassifyFile()} style={{ marginLeft: "20px" }}> Perform View Classification </Button>)}
                  { this.state.show_validate && (<Button onClick={() => this.viewClassifyFile()} style={{ marginLeft: "20px" }}> Perform Validation </Button>)}
                  {/* <Button onClick={() => this.annotationJob()} style={{ marginLeft: "20px" }}>  Create Annotation Task </Button> */}
                  <Button onClick={() => this.refresh()} style={{ marginLeft: "20px" }}>  Refresh </Button>
                  {/* <Button onClick={() => this.redirectToAnnotation()} style={{ marginLeft: "80px" }}>Go to CVAT</Button> */}
                </Toolbar>
              }
              onAddFilter={
                filter =>
                  this.setState({ filters: this.handleFilterChange(filter) })
              }
              onClearFilters={() => this.setState({
                filter: {}
              })}
              onGridSort={(sortColumn, sortDirection) =>
                this.setState({
                  rows: this.sortRows(this.state.rows, sortColumn, sortDirection),
                  sortColumn: sortColumn, 
                  sortDirection:sortDirection
                })               
              }
              rowSelection={{
                showCheckbox: true,
                onRowsSelected: this.onRowsSelected,
                onRowsDeselected: this.onRowsDeselected,
                selectBy: {
                  indexes: this.state.selectedIndexes
                }
              }}
            />
          </LoadingOverlay>
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next >"
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          forcePage={this.current_page}
          pageCount={Math.ceil(this.total_row_count / PAGE_SIZE)}
          previousLabel="< Previous"
          renderOnZeroPageCount={null}
          onPageChange={this.handlePageChange}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
    )
  }
}

export default withMyHook(ReactDataGridComponent)
