import React, { Component, useState } from "react";
import ReactDOM from "react-dom";
import ReactDataGrid from "react-data-grid";
import ReactPaginate from 'react-paginate';
import { Toolbar, Data, Filters, Editors, } from "react-data-grid-addons";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from '@material-ui/core/Checkbox';
import Form from 'react-bootstrap/Form'
import { Row, Col } from 'react-bootstrap';
import axios from "axios";
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import DiacomContainer from "../../components/diacomContainer/dicomModalUpdated"
import LoadingOverlay from 'react-loading-overlay';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import BoostrapModalComponent from '../../components/modalComponent/bootstrap_modal.component'
import createNotification from "../../components/notification/notificationComponent"
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from 'react-bootstrap/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import withMyHook from "./hookWrapper"
import { EVENTS, MODALITY_TASK } from "../../common/constants";
import eventBus from "../../common/eventBus";
import FileApi from "../../api/file.api";


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',

    // backgroundColor:"#374f65"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));
const URL = process.env.REACT_APP_BACKEND
const CVATLINK = process.env.REACT_APP_CVAT
const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE

class jobCreationTab extends Component {
  constructor(props) {
    super(props);
    this.file_api = new FileApi();
    this.selected_modality = MODALITY_TASK.DEFAULT();
    this.filteredColumns = this.getFilteredColumns();
    this.total_row_count = 0;
    this.current_page = 1;
    this.state = {
      filters: {},
      rows: this.props.rows,
      selectedIndexes: [],
      selectedID: [],
      loader: false,
      open: false,
      threshold: '',
      column: '',
      max_column_name: {},
      max_column_value: {},
      id: '',
      checkallcheckbox: false,
      checksinglecheckbox: false,
      file_name: null,
      max_value: null,
      max_value_column: null,
      modified_on: null,
      processing_stage: null,
      created_on: null,
      filtersApplied: false,
      file_id: null,
      rows: [],
      current_page: 2,
      sortColumn: null,
      sortDirection: null,
      show_cvat: this.canShowCvat(),
      show_annotation: this.canShowCvat(),
      show_predicted_view: this.canShowPredictedView()
    }
  }

  defaultColumnProperties = {
    width: 178
  };

  async loadFiles({sort=false, error_msg=null, bg=false}={}) {
    if (!bg) this.setState({ loader: true });
    try {
      let files;
      if ([MODALITY_TASK.Echo_Segmentation, MODALITY_TASK.Echo_Classification].indexOf(this.selected_modality) > -1) {
        files = await this.file_api.getFiles({
          file_type: 'echo',
          throw_on_error:(error_msg != null)
        });
      } else if (this.selected_modality == MODALITY_TASK.ECG_Classification) {
        files = await this.file_api.getFiles({
          file_type: 'ecg',
          throw_on_error:(error_msg != null)
        });
      }
      if (files) {
        if (sort) {
          this.setState({ rows: this.sortRows(files, this.state.sortColumn, this.state.sortDirection) });
        } else {
          this.setState({ rows: files, selectedIndexes: [] });
        }
      }
    } catch (e) {
      if (error_msg != null) {
        createNotification('error', error_msg);
      } else {
        createNotification('error', 'Something went wrong.');
      }
    } finally {
      this.setState({ loader: false });
    }
  }

  async loadAnnotatedFiles({page_number=null, sort=false, error_msg=null, bg=false} = {}) {
    if (!bg) this.setState({ loader: true });
    try {
      let files;
      if (this.selected_modality == MODALITY_TASK.Echo_Segmentation) {
        files = await this.file_api.getAnnotationByThreshold({
          page:page_number,
          file_extention: '.dcm'
        },{
          throw_on_error:(error_msg != null)
        });
      } else if (this.selected_modality == MODALITY_TASK.Echo_Classification) {
        files = await this.file_api.getAnnotationByThreshold({
          page:page_number,
          file_extention: '.dcm'
        },{
          throw_on_error:(error_msg != null)
        });
      } else if (this.selected_modality == MODALITY_TASK.ECG_Classification) {
        files = await this.file_api.getAnnotationByThreshold({
          page:page_number,
          file_extention: '.xml'
        },{
          throw_on_error:(error_msg != null)
        });
      }
      if (files) {
        this.total_row_count = files.count;
        if (sort) {
          this.setState({ rows: this.sortRows(files.results, this.state.sortColumn, this.state.sortDirection) });
        } else {
          this.setState({ rows: files.results, selectedIndexes: [] });
        }
      }
    } catch (e) {
        console.error(e);
        createNotification('error', error_msg || 'Something went wrong.');
    } finally {
        this.setState({ loader: false });
    }
}



  async loadThresholdFilter({sort=false, error_msg=null, bg=false} = {}) {
    if (!bg) this.setState({ loader: true });
    try {
      const files = await this.getThresholdFiles(error_msg != null);
      if (files) {
        if (sort) {
          this.setState({ rows: this.sortRows(files, this.state.sortColumn, this.state.sortDirection) });
        } else {
          this.setState({ rows: files, selectedIndexes: [] });
        }
      }
    } catch (e) {
      if (error_msg != null) {
        createNotification('error', error_msg);
      } else {
        createNotification('error', 'Something went wrong.');
      }
    } finally {
      this.setState({ loader: false });
    }
  }

  async getThresholdFiles(throw_on_error) {
    let processingStage = this.state.processing_stage != null
     ? this.state.processing_stage.toString().toUpperCase()
     : this.state.processing_stage;
    return await this.file_api.getThresholdFilterView({
      "file_id_value": this.state.file_id,
      "file_name_value": this.state.file_name,
      "file_extention": (this.selected_modality == MODALITY_TASK.ECG_Classification) ? '.xml' : '.dcm',
      "max_value_value": this.state.max_value,
      "max_value_column_value": this.state.max_value_column,
      "modified_on_value": this.state.modified_on,
      "processing_stage_value": processingStage,
      "created_on_value": this.state.created_on
    },{
      throw_on_error:throw_on_error
    });
  }

  async componentDidMount() {
    eventBus.on(EVENTS.MODALITY_SELECTED, this.onModalitySelected);
    setTimeout(async () => {
      this.setState({ loader: true });
      await this.loadAnnotatedFiles({error_msg:"Something went wrong"});
    }, 500);
    this.autoReload();
  }

  autoReload = () => {
    try {
      setInterval(async () => {
        if (!this.state.filtersApplied) {
          await this.loadAnnotatedFiles({sort: true, bg:true});
        } else {
          await this.loadThresholdFilter({sort:true, bg:true});
        }
      }, 30000);
    } catch (e) { }
  }

  handleChange = (event) => {
    // setAge(Number(event.target.value) || '');
    this.setState({ column: (event.target.value) })
  };

  handleThresholdChange = (event) => {
    // setAge(Number(event.target.value) || '');
    this.setState({ threshold: (event.target.value) })
  };

  clearSelect = async () => {
    this.setState({ threshold: '', column: '', loader: true })
    await this.loadAnnotatedFiles({error_msg:"Something went wrong"});
  }

  redirectToAnnotation = async () => {
    //  const myHookValue = this.props.myHookValue;

    // // const accessToken = await myHookValue.getAccessTokenSilently({
    // //     audience: process.env.REACT_APP_AUTH0_AUDIENCE
    // // //   scope: 'read:posts',
    // // });

    // let user_name = myHookValue.user.name
    // let user_email = myHookValue.user.email
    // let role = myHookValue.user["http://localhost:3000//roles"][0]? myHookValue.user["http://localhost:3000//roles"][0] : null

    // // We need to pass roles from here
    //   // this is the one http://localhost:3000/api/v1/auth/sso_auth - final
    // fetch(`${process.env.REACT_APP_CVAT}/api/v1/auth/sso_auth`, {
    //     method: "POST",
    //     credentials: 'include',
    //     headers: { 'Content-Type': 'application/json' ,
    //     // mode: 'no-cors',
    //     Authorization: `Bearer ${accessToken}`

    // },
    // body: JSON.stringify({
    //     "username": user_name,
    //     "email": user_email,
    //     "role": role
    //   })

    // })
    // .then((response) => {console.log(response)
    //     if (response.status == 401) {
    //         alert("You are unauthorized to access CVAT. Please contact Central Body for details.")
    //     }
    //     else {
    //         window.open(`${process.env.REACT_APP_CVAT}/tasks`, "_parent");
    //     }

    // }).catch((err) => alert(err))
    window.open(`${process.env.REACT_APP_CVAT}/tasks`, "_blank");
  }

  applyThreshold = async () => {
    this.setState({ loader: true })
    const files = await this.file_api.getClassificationByThreshold({
      "threshold_value": this.state.threshold,
      "column": this.state.column.toString()
    });
    this.setState({ rows: files, loader: false });
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  };

  handleClose = () => {
    this.setState({ open: false })
  };

  ProgressBarFormatter = ({ value }) => {
    // return <a href="javascript:void(0);" onClick={(e) => viewdiacom(value,e)}>{value}</a> ;
    return <Link onClick={() => this.viewdiacom(value)} >{value}</Link>
    //  <Button onClick={() => viewdiacom(value)}> {value}</Button>
  };

  FileNameFormatter = ({ row }) => {
    return (
      <div >
        <div style={{ overflow: "hidden", textOverflow: "ellipsis", float: "left", width: "80%" }}>
          {row.id}
        </div>
        <div>
          <Tooltip title={<Typography color="inherit"> {row.id}</Typography>} interactive>
            <InfoIcon />
          </Tooltip>
        </div>
      </div>
    )
  };

  FileIdFormatter = ({ row }) => {

    // return (row[0])
    return (
      //       <div>
      //       <Tooltip title={ <Typography color="inherit"> {row.file_name}</Typography>} interactive>
      //   <InfoIcon/>

      // </Tooltip>

      //        <a href="javascript:void(0);" onClick={() => this.showFileData(row.id)}>
      //       {row.file_name}
      //     </a>
      //     </div>
      <div >
        <div style={{ overflow: "hidden", textOverflow: "ellipsis", float: "left", width: "80%" }}>
          <a href="javascript:void(0);" onClick={() => this.showFileData(row.id)}>
            {row.file_name}
          </a>
        </div>
        <div>
          <Tooltip title={<Typography color="inherit"> {row.file_name}</Typography>} interactive>
            <InfoIcon />
          </Tooltip>
        </div>
      </div>
    )
  };

  maxValueFormatter = ({ value }) => {
    if (value != null || value != undefined) {
      if (value != null || value != undefined) {
        const period = 4
        if (value != null || value != undefined) {
          let numToStr = value.toString();
          let contains_period = numToStr.search(".")
          if (contains_period == 0) {
            let position_period = numToStr.lastIndexOf(".")
            let totallength = numToStr.length;
            return numToStr.substring(0, position_period + period)
          } else {
            return numToStr;
          }
        }
        return ""
      }
    } else {
      return ("No Data Found ")
    }
  }

  maxColumnFormatter = ({ value }) => {
    if (value != null || value != undefined) {
      return (value);
    } else {
      return ("No Data Found ")
    }
  }

  DateFormatter = ({ value }) => {
    var datee = new Date(value * 1000)
    var date = datee.toLocaleString('en-US');
    return date
  };

  checkboxFormatter = ({ row }) => {
    return <Checkbox
      id={row.id}
      color="primary"
      inputProps={{ 'aria-label': 'controlled' }}
      onClick={() =>
        this.state.selectedID.includes(row.id) ? this.onRowDeselected(row) : this.onRowSelected(row)
      }
      checked={
        this.state.selectedID.includes(row.id)
      }
    />
  }

  viewdiacom = (value, e) => {
    ReactDOM.render(<DiacomContainer file_name={value} filePath={value} fileType={value} id={Date.now()} />,
      document.getElementById('modal_div'))
  }

  showFileData = async (value) => {
    this.setState({ loader: true })
    const file_data = await this.file_api.getFiles({file_type:value});
    this.setState({ loader: false })
    if (file_data) {
      return (
        ReactDOM.render(
          <BoostrapModalComponent
            data={file_data}
            modal_type="study_grid"
            // show_modal={true}
            id={Date.now()}
          />,
          document.getElementById("modal_div")
        )
      )
    }
  }

  refresh = async () => {
    this.setState({
      loader: true,
      file_name: "",
      file_id: "",
      max_value: "",
      max_value_column: "",
      modified_on: "",
      processing_stage: "",
      created_on: "",
      filtersApplied: false,
      show_cvat: this.canShowCvat(),
      show_annotation: this.canShowCvat(),
      show_predicted_view: this.canShowPredictedView(),
    });
    await this.loadAnnotatedFiles({error_msg: 'Something went wrong'});
  }

  handleFilterChangeState = (event, colName) => {
    this.setState({ [colName] : (event.target.value) });
  }

  getFilteredColumns = () => {
    return this.columns.filter((column) => {
      return !column.display_on || (column.display_on.indexOf(this.selected_modality) > -1);
    }).map(c => ({ ...c, ...this.defaultColumnProperties }));
  }

  classificationKey = [
    "a2c",
    "a2c_laocc",
    "a2c_lvocc_s",
    "a3c",
    "a3c_laocc",
    "a3c_lvocc_s",
    "a4c",
    "a4c_far",
    "a4c_laocc",
    "a4c_lvocc_s",
    "a4c_rv",
    "a4c_rv_laocc",
    "a5c",
    "apex",
    "other",
    "plax_far",
    "plax_lac",
    "plax_laz",
    "plax_laz_ao",
    "plax_plax",
    "psax_avz",
    "psax_az",
    "psax_mv",
    "psax_pap",
    "rvinf",
    "subcostal",
    "suprasternal"
  ]

  selectors = Data.Selectors;

  columns = [
    // {
    //   name:<Checkbox   inputProps={{ 'aria-label': 'controlled' }} 
    //   color="primary"
    //   onClick={() => this.state.checkallcheckbox == false?
    //     this.onRowsSelected(this.props.rows):
    //     this.onRowsDeselected(this.props.rows)
    //   }
    //   />,
    //    formatter: this.checkboxFormatter,
    //   sortable : false,
    //   filterable: false

    // },
    {
      key: "file_name",
      name: "File Name",
      formatter: this.FileIdFormatter,
      filterable: true,
      sortable: true,
      // editable: true
    },
    {
      key: "id",
      name: "File Id",
      filterable: true,
      sortable: true,
      formatter: this.FileNameFormatter,
    },
    {
      key: "max_value_column",
      name:
        <Tooltip title="Values will be filled after view classification is performed." arrow>
          <b> Predicted View</b>
        </Tooltip>,
      //   <Tooltip title="Add">
      //      Predicted view
      // </Tooltip>, 
      formatter: this.maxColumnFormatter,
      filterable: true,
      sortable: true,
      display_on: [MODALITY_TASK.Echo_Classification, MODALITY_TASK.Echo_Segmentation]
    },
    {
      key: "max_value",
      name:
        <Tooltip title="Values will be filled after view classification is performed." arrow>
          <b>Probability of Predicted View</b>
        </Tooltip>,
      formatter: this.maxValueFormatter,
      filterable: true,
      sortable: true,
      display_on: [MODALITY_TASK.Echo_Classification, MODALITY_TASK.Echo_Segmentation]
    },
    {
      key: "processing_stage",
      name: "Processing Stage",
      filterable: true,
      sortable: true,
      display_on: [MODALITY_TASK.Echo_Classification, MODALITY_TASK.Echo_Segmentation]
    },
    {
      key: "condition",
      name: "Condition",
      filterable: true,
      sortable: true,
      display_on: [MODALITY_TASK.Echo_Classification, MODALITY_TASK.ECG_Classification]
    },
    {
      key: "processing_stage",
      name: "Validation Stage",
      filterable: true,
      sortable: true,
      display_on: [MODALITY_TASK.ECG_Classification]
    },
    {
      key: "frame_time",
      name: "Sampling Rate",
      filterable: true,
      sortable: true,
      display_on: [MODALITY_TASK.ECG_Classification]
    },
    {
      key: "created_on",
      name: "Date of Task Creation",
      formatter: this.DateFormatter,
      filterable: true,
      sortable: true,
    },

    {
      key: "modified_on",
      name: "Modified On",
      formatter: this.DateFormatter,
      filterable: true,
      sortable: true,
    },
  ];

  getRows(rows, filters, row) {
    // const row = Object.assign({}, rows, {nameandpic});
    return this.selectors.getRows({ rows, filters, row });
  }

  onRowSelected = (row) => {
    this.setState({
      selectedID: this.state.selectedID.concat(
        row.id
      ),
      selectedIndexes: this.state.selectedIndexes.concat(
        row.rowIdx
      ),
    });
  };

  // onRowsSelected = (rows) => {

  //       this.setState({
  //         selectedIndexes: this.state.selectedIndexes.concat(
  //           rows.map((r) => r.rowIdx
  //           )
  //         ),
  //         selectedID: this.state.selectedID.concat(
  //           rows.map((r) => r.id)
  //         ),
  //         checkallcheckbox: !this.state.checkallcheckbox
  //       });

  //     };

  onRowsSelected = (rows) => {
    rows.map((row) => {
      if (row.row.processing_stage == "DICOM_TO_NPY_ARRAY" || 
        row.row.processing_stage == "PROCESSING") {
        createNotification('error', "Files are already in processing ")
      } else {
        this.setState({
          selectedIndexes: this.state.selectedIndexes.concat(
            rows.map((r) => r.rowIdx
            )
          ),
          selectedID: this.state.selectedID.concat(
            rows.map((r) => r.row.id)
          )
        });
      }
    });
  };

  EmptyRowsView = () => {
    const message = "No data to show";
    return (
      <div
        style={{ textAlign: "center", padding: "100px", backgroundColor: "#ddd" }}
      >
        <h3>No Records Found</h3>
      </div>
    );
  };

  // onRowsDeselected = (rows) => {

  //       let rowIndexes = rows.map((r) => r.rowIdx);
  //       let rowId = rows.map((r) => r.id)
  //       this.setState({
  //         selectedID: this.state.selectedID.filter(
  //           (i) => rowId === -1
  //         )
  //       });
  //       this.setState({
  //         selectedIndexes: this.state.selectedIndexes.filter(
  //           (i) => rowIndexes === -1
  //         )
  //       });
  //       this.setState({checkallcheckbox: false})
  //     };

  // onRowDeselected = (row) => {

  //       let rowIndexes = row.rowIdx;
  //       let rowId = row.id
  //       this.setState({
  //         selectedID: this.state.selectedID.filter(
  //            (i) => rowId !=i
  //           //  rowId 
  //         )
  //       });
  //       this.setState({
  //         selectedIndexes: this.state.selectedIndexes.filter(
  //           (i) => rowIndexes != i
  //           // (i) => rowIndexes.indexOf(i) === -1
  //         )
  //       });
  //     };

  onRowsDeselected = (rows) => {
    let rowIndexes = rows.map((r) => r.rowIdx);
    let rowId = rows.map((r) => r.row.id)
    this.setState({
      selectedID: this.state.selectedID.filter(
        (i) => rowId.indexOf(i) === -1
      )
    });
    this.setState({
      selectedIndexes: this.state.selectedIndexes.filter(
        (i) => rowIndexes.indexOf(i) === -1
      )
    });
  };

  annotationJob = async () => {
    if (this.state.selectedIndexes.length == 0) {
      return createNotification('error', "Please select file to Annotate")
    }
    try {
      this.setState({ loader: true })
      await this.file_api.createAnnotationTask({
        "file_ids": this.state.selectedID
      });
      createNotification('success', "Queued for Annotation, Please wait");
      const delay = (this.state.selectedIndexes.length < 5) ? 3000 : 8000;
      setTimeout(function () {
        window.open(`${process.env.REACT_APP_CVAT}/tasks`, "_blank")
      }, delay);
    } catch (e) {
      createNotification('error', "Something went wrong");
    } finally {
      this.setState({ loader: false, selectedIndexes: [], selectedID: [] });
    }
  }

  navigateToCvat = () => {
    // window.open('http://localhost:8080/tasks', "_blank")
  }

  deleteFile = async () => {
    if (this.state.selectedIndexes.length == 0) {
      return createNotification('error', "Please select file to delete")
    }
    try {
      this.setState({ loader: true });
      await this.file_api.deleteFiles({
        "file_ids": this.state.selectedID
      });
      createNotification('success', "Record deleted successfully");
    } catch(e) {
      createNotification('error', "Something went wrong");
    }
    await this.loadAnnotatedFiles({error_msg: "Something went wrong"});
    this.setState({ selectedID: [] });
  }

  viewClassifyFile = async () => {
    try {
      this.setState({ loader: true });
      const result = await this.file_api.getClassificationProcessorUploadStatus({
        "file_ids": this.state.selectedID
      });
      if (result && result.instance_files) {
        createNotification('success', "Queued for View Classification")
        this.setState({ selectedIndexes: [], selectedID: [] });
      } else if (result && result.message) {
        createNotification('error', json.message)
      } else {
        createNotification('error', "Something went wrong")
      }
    } catch(e) {
      createNotification('error', "Something went wrong")
    } finally {
      this.setState({ loader: false });
    }
  }

  handleFilterChange = async (filter) => {
    let cancelToken;
    this.setState({ filterapplied: true })
    this.setState({ filter: filter })
    const newFilters = { ...this.state.filters };
    console.log("Canceltoken", typeof cancelToken)
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Canceling previous request")
    }
    cancelToken = axios.CancelToken.source()
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
      const files = await this.file_api.getFilteredData({
        "column": filter.column.key,
        "column_value": filter.filterTerm
      });
      this.setState({ rows: files });
    }
  }

  resetFilter() {
    this.setState({
      loader: true,
      file_name: "",
      file_id: "",
      max_value: "",
      max_value_column: "",
      modified_on: "",
      processing_stage: "",
      created_on: "",
      filtersApplied: false,
      show_cvat: this.canShowCvat(),
      show_annotation: this.canShowCvat(),
      show_predicted_view: this.canShowPredictedView(),
    });
  }

  clearFilter = async () => {
    this.resetFilter();
    await this.loadAnnotatedFiles({error_msg: "Something went wrong"});
  }

  applyFilersonGrid = () => {
    this.setState({ filtersApplied: true });
    this.loadThresholdFilter();
  }

  sortRows = (initialRows, sortColumn, sortDirection) => {
    const { rows } = this.state;
    if (!Array.isArray(rows)) {
      // Handle non-iterable state.rows
      console.error('State.rows is not iterable');
      return;
    }
    const comparer = (a, b) => {

      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    return sortDirection === "NONE" ? initialRows : [...this.state.rows].sort(comparer);
  };

  componentWillUnmount() {
    eventBus.removeListener(EVENTS.MODALITY_SELECTED, this.onModalitySelected);
  }

  canShowCvat = () => {
    return (MODALITY_TASK.Echo_Segmentation == this.selected_modality);
  }

  canShowPredictedView = () => {
    return (MODALITY_TASK.ECG_Classification != this.selected_modality);
  }

  onModalitySelected = async (selected_modality) => {
    this.selected_modality = selected_modality;
    this.current_page = 1;
    this.resetFilter();
    this.filteredColumns = this.getFilteredColumns(selected_modality);
    await this.loadAnnotatedFiles({error_msg:"Something went wrong"});
    const show = this.canShowCvat();
    this.setState({
      show_cvat: show, show_annotation: show,
      show_predicted_view: this.canShowPredictedView()
    });
  }
 
  
  handlePageChange = (page) => {
    
        this.loadAnnotatedFiles({ page_number:page.selected+1,sort: this.state.sort, bg: false });  
};

  render() {
    this.filteredRows = this.getRows(this.state.rows, this.state.filters);
    return (
      <div>
        <NotificationContainer />
        <div>
          {/* <span>
              {this.state.selectedIndexes.length} {rowText} selected 
            </span> */}
          {/* <div id="modal_div"> </div>
          <form className="container" style={{ marginBottom: "10px", marginTop: "10px", backgroundColor: "white", paddingTop: "10px", paddingBottom: "10px" }} >
            <FormControl className="formControl" style={{ marginLeft: "10px", marginTop: "-10px", minWidth: 150 }} >
              <InputLabel htmlFor="demo-dialog-native">Selct column name</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={this.state.column}
                onChange={(event) => this.handleChange(event)}
                input={<Input />}
              >
                {
                  this.classificationKey.map((key) => {
                    return (
                      <MenuItem value={key}>
                        <em>{key}</em>
                      </MenuItem>)
                  })
                }
              </Select>
            </FormControl>
            <FormControl className="formControl" style={{ marginLeft: "20px", marginTop: "-10px", minWidth: 100 }}>
              <TextField id="standard-basic" label="Enter threshold value" onChange={(event) => this.handleThresholdChange(event)} value={this.state.threshold} />
            </FormControl>
            <Button onClick={() => this.applyThreshold()}
              style={{ marginLeft: "20px" }} disabled={this.state.column == '' || this.state.threshold == ''}>Apply
            </Button>
            <Button onClick={() => this.clearSelect()}
              disabled={this.state.column == '' && this.state.threshold == ''}
              style={{ marginLeft: "20px" }}>Clear</Button>
            <Button onClick={() => this.redirectToAnnotation()} style={{ marginLeft: "80px" }}>Go to CVAT</Button>
          </form> */}
          <div id="modal_div"> </div>
          <form style={{ marginBottom: "10px", marginTop: "10px", backgroundColor: "white", paddingTop: "10px", paddingBottom: "10px" }} >
            <FormControl className="formControl" style={{ marginLeft: "10px", marginTop: "-10px", minWidth: 100 }}>
              <TextField id="standard-basic" label="File Name" onChange={(event) => this.handleFilterChangeState(event, "file_name")} value={this.state.file_name} />
            </FormControl>
            <FormControl className="formControl" style={{ marginLeft: "10px", marginTop: "-10px", minWidth: 100 }}>
              <TextField id="standard-basic" label="File Id" onChange={(event) => this.handleFilterChangeState(event, "id")} value={this.state.file_id} />
            </FormControl>
            {this.state.show_predicted_view && (<FormControl className="formControl" style={{ marginLeft: "20px", marginTop: "-10px", minWidth: 150 }} >
              <InputLabel htmlFor="demo-dialog-native">Predicted View</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={this.state.max_value_column}
                onChange={(event) => this.handleFilterChangeState(event, "max_value_column")}
                input={<Input />}
              >
                {
                  this.classificationKey.map((key) => {
                    return (
                      <MenuItem value={key}>
                        <em>{key}</em>
                      </MenuItem>)
                  })
                }
              </Select>
            </FormControl>)}
            {/* <FormControl className="formControl" style={{ marginLeft: "20px", marginTop: "-10px", minWidth: 100 }}>
    <TextField id="standard-basic" label="Predicted View Probability" onChange={(event) => this.handleFilterChangeState(event,"max_value")} value={this.state.max_value} />
    </FormControl> */}
            {/* <FormControl className="formControl" style={{ marginLeft: "20px", marginTop: "-10px", minWidth: 100 }}>
<TextField id="standard-basic" label="Processing Stage" onChange={(event) => this.handleFilterChangeState(event,"processing_stage")} value={this.state.processing_stage} />
</FormControl> */}
            {/* <FormControl className="formControl" style={{ marginLeft: "20px", marginTop: "-10px", minWidth: 100 }}> */}
            {/* <TextField id="standard-basic" label="Date of Task Creation"
     onChange={(event) => this.handleFilterChangeState(event,"created_on")} 
     value={this.state.created_on} /> */}
            {/* type="datetime-local" */}
            {/* <TextField
          id="date"
          label="Select Date"
          type="datetime-local"
           defaultValue="MM/DD/YYYY"
          value={this.state.created_on}
          onChange={(event) => this.handleFilterChangeState(event, "created_on")}
          InputLabelProps={{
            shrink: true,
          }}
        />
    </FormControl> 
    <FormControl className="formControl" style={{ marginLeft: "20px", marginTop: "10px", minWidth: 100 }}> */}
            {/* <TextField id="standard-basic" label="Modified On" onChange={(event) => this.handleFilterChangeState(event,"modified_on")} value={this.state.modified_on} />  */}
            {/* <TextField
          id="date"
          label="Select Date"
          type="datetime-local"
           defaultValue="MM/DD/YYYY"
          value={this.state.modified_on}
          // onChange={(event) => this.handleDateChange(event, "modified_on")}
          onChange={(event) => this.handleDateChange(event, "modified_on")}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl> */}
            <Button onClick={() => this.applyFilersonGrid()}
              style={{ marginLeft: "20px", marginTop: "10px" }}
            // disabled={this.state.column == '' || this.state.threshold == ''}
            >Apply
            </Button>
            <Button onClick={() => this.clearFilter()}
              // disabled={this.state.column == '' && this.state.threshold == ''}
              style={{ marginLeft: "20px", marginTop: "10px" }}>Clear</Button>
          </form>
          <LoadingOverlay
            active={this.state.loader}
            spinner
            text='Loading...'
          >
            <ReactDataGrid
              rowKey="id"
              columns={this.filteredColumns}
              rowGetter={i => this.filteredRows[i]}
              rowsCount={this.filteredRows.length}
              emptyRowsView={this.EmptyRowsView}
              minHeight={500}
              toolbar={
                <Toolbar enableFilter={false}>
                  <Button onClick={() => this.deleteFile()} style={{ marginLeft: "20px" }}>  Delete </Button>
                  {/* <Button onClick={() => this.viewClassifyFile()} style={{ marginLeft: "20px" }}> View Classsifier </Button> */}
                  {this.state.show_annotation && (<Button onClick={() => this.annotationJob()} style={{ marginLeft: "20px" }}> Send for Annotation</Button>)}
                  <Button onClick={() => this.refresh()} style={{ marginLeft: "20px" }}>  Refresh </Button>
                  {this.state.show_cvat && (<Button onClick={() => this.redirectToAnnotation()} style={{ marginLeft: "80px" }}> CVAT</Button>)}

                </Toolbar>
              }
              onAddFilter={
                filter =>
                  this.setState({ filters: this.handleFilterChange(filter) })
              }
              onClearFilters={() => this.setState({
                filter: {}
              })}
              onGridSort={(sortColumn, sortDirection) =>
                this.setState({
                  rows: this.sortRows(this.state.rows, sortColumn, sortDirection),
                  sortColumn: sortColumn,
                  sortDirection: sortDirection
                })}
              enableCellSelect={true}
              rowSelection={{
                showCheckbox: true,
                onRowsSelected: this.onRowsSelected,
                onRowsDeselected: this.onRowsDeselected,
                selectBy: {
                  indexes: this.state.selectedIndexes
                }
              }}
            />
          </LoadingOverlay>
        </div>
        <div>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next >"
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    forcePage={this.current_page - 1} 
                    pageCount={Math.ceil(this.total_row_count / PAGE_SIZE)}
                    previousLabel="< Previous"
                    renderOnZeroPageCount={null}
                    onPageChange={this.handlePageChange}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </div>
      </div>
    )
  }
}

export default withMyHook(jobCreationTab)
