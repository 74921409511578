import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import ReactDOM from "react-dom";
import DiacomContainer from "../diacomContainer/dicomModalUpdated"
// import DataGrid from 'react-data-grid';
import Table from 'react-bootstrap/Table'
// import "bootstrap/dist/css/bootstrap.min.css";
import { DataGrid } from '@material-ui/data-grid';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CornerstoneElement from '../diacom_viewer_component/dicom_viewer.component'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// import TabPanel from'@material-ui/core/Tab';
// import AppBar from '@material-ui/core/AppBar';
// import { ModalBody } from 'react-bootstrap';

class BoostrapModalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {

            classificationdata: null,
            processingStage: '',
            show: true,
            stack: {
                imageIds: [],
                currentImageIdIndex: 0
            },
            error: '',
            loading: false,
            toasts: []
        }
    }
    componentDidMount() {
        this.setState({
            processingStage: this.props.data.processing_stage,
            classificationdata: this.props.data.view_classification_result
        })
        let that = this;
        let notifyNotFoundDiv = document.getElementById('notifyFileNotFound')
        notifyNotFoundDiv.style.display = "none"
        this.getFileFromGit();
    }
    setToast = (_title, _message, _type, _delay = 3000) => {
        this.setState({
            ...this.state,
            toasts: [{ id: this.getRandumNumber(), 'title': _title, 'message': _message, 'type': _type, delay: _delay }]
        })
    }

    getError = (error) => {
        return error?.response?.data?.error?.message || error?.message || 'Unknown Error';
    }

    getRandumNumber = () => {
        return Math.floor(Math.random() * 100000)
    }
    getFileFromGit() {
        this.setState({ show: true })

        this.setState({
            ...this.state,
            loading: true
        })
        const imageId = this.props.data.presigned_url

        this.setState({
            ...this.state,
            loading: false,
            stack: {
                imageIds: [`dicomweb:${imageId}`],

                currentImageIdIndex: 0
            }
        })
    }

    componentDidUpdate(prevProps) {

        if (this.props.id !== prevProps.id) {
            this.getFileFromGit()
            this.handleShow();
            this.setState({
                processingStage: this.props.data.processing_stage,
                show: true,
                stack: {
                    imageIds: [],
                    currentImageIdIndex: 0
                },
                error: '',
                loading: false,
                toasts: []
            })
            let that = this;
            // ;
        }
    }

    handleShow = () => {

        this.setState({ show: true })
    }

    handleClose = () => {
        this.setState({ show: false })
    }
    getDeltaXformat=(val,period)=>

    {


        if(val != null || val != undefined)

        {

           let numToStr = val.toString();

            let contains_period = numToStr.search(".")

            if(contains_period == 0)

            {

                let position_period = numToStr.lastIndexOf(".")

                let totallength = numToStr.length;

                //  alert(numToStr.substring(0,position_period +3))

                return numToStr.substring(0,position_period + period)
           }
           else
          {
              return numToStr;
          }
    }

    return ""

    }



    componentWillUnmount() {

    }

    render() {

        let imageId = this.props.data.presigned_url
        let modal_body;
        let modal_title;
        let stack = {
            imageIds: [`dicomweb:${imageId}`],

            currentImageIdIndex: 0
        }
        var keys = Object.keys(this.props.data);
        var values = Object.values(this.props.data);
        return (
            <>
                <Modal show={this.state.show} onHide={this.handleClose} dialogClassName="my-modal"
                    aria-labelledby="contained-modal-title-vcenter" backdrop="static" size="md"

                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title >
                        </Modal.Title>
                    </Modal.Header>


                    <Modal.Body id="root" className="table-responsive-md" style={{ height: "70vh", overflow: "scroll" }}>
                        <Tabs defaultActiveKey="first">

                            <Tab eventKey="first" title="DICOM Viewer">

                                <label id="notifyFileNotFound" style={{ display: "none", fontWeight: 'normal', alignItems: 'center' }}>This file cant be displayed. This file doesn't exist on the path specified. </label>


                                {
                                    stack.imageIds.length > 0 && <CornerstoneElement stack={{ ...stack }} />
                                }

                                {/* {
ReactDOM.render(<DiacomContainer file_name={values[2]} filePath={values[2]} fileType={values[2]} id={Date.now()} />,
document.getElementById('modal_div'))
} */}


                            </Tab>

                            <Tab eventKey="second" title="View Classification Output">
                                {
                                    this.props.data.view_classification_result != null ?
                                        <div>  {
                                            <Table striped bordered hover size="md">
                                            <tbody>
                                                <tr>
                                                    <th>Predicted View</th>
                                                    <th>Probablity of Predicted View</th>
                                                </tr>
                                                {
                                                Object.keys(this.props.data.view_classification_result).map((key) => (
                                                        // let numb=parseFloat(this.props.data.view_classification_result[key])
                                                <tr className="table-row">
                                                    <td key={`tablevalue-${key}`}>{key}</td>
                                                    {
                                                   
                                                                        <td key={`tablevalue-${this.props.data.view_classification_result[key]}`}>
                                                                           { this.getDeltaXformat(this.props.data.view_classification_result[key],4)}


                                                                            </td>
                                                                
                                                    }
                                                </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                       
                                           
                                        }
                                        </div>
                                        :
                                        "No data found"


                                }

                            </Tab>



                        </Tabs>
                        <div id="modal_div"></div>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default BoostrapModalComponent;





