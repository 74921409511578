import React from "react";
import loading1 from "./loading.svg";

const Loading = () => (
  <div className="spinner">
    <img src={loading1} alt="Loading" />
  </div>
);

export default Loading;
