import { React, Component } from "react";
import Sidebar from "../components/sideBar/sideBar"
import Header from "../components/header/header"


const BasicLayout = ({ children }) => {
  return (
    <>
        <Header/>
        <Sidebar/>
        <div className="content-wrapper">
            {children}
        </div>
        
    </>
  );
};

export default BasicLayout