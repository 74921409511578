import { REQUEST_METHOD } from '../common/constants';
import Api from './api'

export default class FileApi extends Api {

    async getFiles({file_type=null, page=null, throw_on_error=false} = {}) {
        try {
            const uri = `files/${file_type != null ? file_type : ''}`;
            let params = null;
            if (page != null) {
                params = { page };
            }
            return await this.invokeService(uri, { params });
        } catch (e) {
            if (throw_on_error) throw e
        }
    }

    async getClassificationByThreshold(data, {throw_on_error=false} = {}) {
        try {
            return await this.invokeService(
                'classification/get_threshold/files', {
                method: REQUEST_METHOD.POST,
                data: JSON.stringify(data)
            });
        } catch (e) {
            if (throw_on_error) throw e
        }
    }

    async getAnnotationByThreshold(data, { throw_on_error = false } = {}) {
        try {
            let params = null;
            if (data.page != null) {
                params = { page:data.page };
            }
            const response = await this.invokeService('annotation/get_threshold_value/', {
                method: REQUEST_METHOD.POST,
                data: JSON.stringify(data),
                params: params
            });
            if (response && response.results) {
                return response;
            } else {
                console.error("No results found in the response", response);
                return [];
            }
        } catch (e) {
            if (throw_on_error) throw e;
            console.error("Error fetching annotation:", e);
            return null; 
        }
    }
    

    async getFilteredData(data, {throw_on_error=false} = {}) {
        try {
            return await this.invokeService(
                'get_filtered_data/', {
                method: REQUEST_METHOD.POST,
                data: JSON.stringify(data)
            });
        } catch (e) {
            if (throw_on_error) throw e
        }
    }

    async getThresholdFilterView(data, {throw_on_error=false} = {}) {
        try {
            return await this.invokeService(
                'ThresholdFilterViewSet/', {
                method: REQUEST_METHOD.POST,
                data: JSON.stringify(data)
            });
        } catch (e) {
            if (throw_on_error) throw e
        }
    }

    async createAnnotationTask(data, {throw_on_error=false} = {}) {
        try {
            return await this.invokeService(
                'annotation/create_task/files',{
                method: REQUEST_METHOD.POST,
                data: JSON.stringify(data)
            });
        } catch (e) {
            if (throw_on_error) throw e
        }
    }

    async deleteFiles(data, {throw_on_error=false} = {}) {
        try {
            return await this.invokeService(
                'files/', {
                method: REQUEST_METHOD.DELETE,
                data: JSON.stringify(data),
                no_response: true
            });
        } catch (e) {
            if (throw_on_error) throw e
        }
    }

    async getClassificationProcessorUploadStatus(data, {throw_on_error=false} = {}) {
        try {
            return await this.invokeService(
                'classification/processor/uploads/status', {
                method: REQUEST_METHOD.PUT,
                data: JSON.stringify(data)
            });
        } catch (e) {
            if (throw_on_error) throw e
        }
    }
}