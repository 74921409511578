// src/auth/auth0-provider-with-history.js

import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience=process.env.REACT_APP_AUTH0_AUDIENCE;
  const frontendURL=process.env.REACT_APP_FRONTEND
  const { user} = useAuth0();


  const onRedirectCallback = (appState) => {
      
    // history.push(appState?.returnTo || window.location.pathname);
     history.push(appState?.returnTo ||"/dashboard")
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
       redirectUri={`${frontendURL}/dashboard`}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;

