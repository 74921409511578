import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./private-route"
import LoginComponent from "../components/loginComponent/loginComponent"
import DashBoard from "../screens/Dasboard/dashboard"
import Tables from "../components/tables/tables"

const Routes = () => {
  return (
        
            <Switch>
                <Route path="/" exact component={LoginComponent} />
                {/* <Route path="/tables" component={Tables} /> */}
                <PrivateRoute path="/dashboard" component={DashBoard} />
                <PrivateRoute path="/tables" component={Tables} />
            </Switch>
            
        
    );
};

export default Routes;

