import React, { Component } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button"
import { Redirect } from 'react-router-dom'
import { useEffect, useState } from "react";
import Cookies from 'js-cookie'

const LoginComponent = () => {
  
  const { user, loginWithRedirect, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const [userMetadata, setUserMetadata] = useState(null);
  const [token, setToken] = useState(null);
  

  async function checkUser() {
  
    if (!isAuthenticated) {
        loginWithRedirect();
    }
    else {
        history.push("/dashboard");
    }
  } 

  useEffect(() => {
    
    (async () => {
      try {
        const token = await getAccessTokenSilently({
           audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        //   scope: 'read:posts',
        });
        console.log("token",token)
        setToken(token);
        // Cookies.set('Auth0token', token)
        sessionStorage.setItem("Auth0token", token);

      }
       catch (e) {
        console.error(e);
      }
    })();
    
  }, [getAccessTokenSilently]);





  return (
    <div className="content">
      <div className="brand">
        <a className="link" href="index.html">OBI</a>
      </div>
      <form id="login-form">
        <h2 className="login-title">USER LOGIN</h2>
        <div className="form-group">
          <Button
            onClick={() => checkUser()}
            id="qsLoginBtn"
            variant="primary"
            className="btn-margin"
          >
            Log In
          </Button>
        </div>
      </form>

      <div className="sidenav-backdrop backdrop"></div>
      <div className="preloader-backdrop">
        <div className="page-preloader">Loading</div>
      </div>
    </div>
  )
}

export default LoginComponent;