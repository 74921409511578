import React from "react"
import { Link } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react";

const SideBar=()=> {
    const { user, loginWithRedirect, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const URL = process.env.REACT_APP_FRONTEND
    let role= user[`${URL}/roles`]
    return (
        <>
        <nav className="page-sidebar" id="sidebar">
            <div id="sidebar-collapse">
                <div className="admin-block d-flex">
                    <div>
                        <img src={user.picture || "./assets/img/admin-avatar.png"} alt="" width="45px" />
                    </div>
                    <div className="admin-info">
                        <div className="font-strong">{user.name}</div><small>{role}</small></div>
                    </div>
                    <ul className="side-menu metismenu">
                        <li></li>
                        <li></li>
                        <li>
                        {/* <a className="active" href="index.html"><i className="sidebar-item-icon fa fa-th-large"></i>
                            <span className="nav-label" ><Link to="/dashboard">Dashboard</Link></span>
                            
                        </a> */}
                         <Link to="/dashboard"><i className="sidebar-item-icon fa fa-th-large"></i><span className="nav-label">Files</span></Link>
                    </li>
                    {/* <li className="heading">FEATURES</li> */}
                    {/* <li>
                        <Link to="/tables"><i className="sidebar-item-icon fa fa-bookmark"></i><span className="nav-label">Tables</span></Link>
                        <a href="icons.html"><i className="sidebar-item-icon fa fa-smile-o"></i>
                            <span className="nav-label">Settings</span>
                        </a>
                    </li> */}
                    {/* <li>
                        <Link to="/dashboard"><i className="sidebar-item-icon fa fa-smile-o"></i><span className="nav-label">Settings</span></Link>
                        <a href="icons.html"><i className="sidebar-item-icon fa fa-smile-o"></i>
                            <span className="nav-label">Settings</span>
                        </a>
                    </li> */}
                </ul>
            </div>
        </nav>
        </>
    )
}

export default SideBar