export const MODALITY_TASK = {
    Echo_Segmentation: "Echo Segmentation",
    Echo_Classification: "Echo Classification",
    ECG_Classification: "ECG Classification",
    DEFAULT: () => {
        return MODALITY_TASK.Echo_Segmentation;
    }
};

export const EVENTS = {
    MODALITY_SELECTED: 'MODALITY_SELECTED'
}

export const REQUEST_METHOD = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
}