import React, { Component } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { useEffect, useState }  from "react";
import { EVENTS, MODALITY_TASK } from '../../common/constants';
import eventBus from "../../common/eventBus";

const Header = () => {

    const { logout, user, getAccessTokenSilently } = useAuth0();
    const history = useHistory();
    const [token, setToken] = useState(null);
    const frontendURL = process.env.REACT_APP_FRONTEND
    const [modalityTask, setModality] = useState(MODALITY_TASK.Echo_Segmentation);

    const handleFilterChangeState = (e, value) => {
        e.preventDefault();
        setModality(value);
        eventBus.emit(EVENTS.MODALITY_SELECTED, value);
    }
    
//  useEffect(() => {
//     (async () => {
//       try {
//         const token = await getAccessTokenSilently({
//            audience: 'https://dev--iqkz8be.us.auth0.com/api/v2/',
//         //   scope: 'read:posts',
//         });
//         console.log("token",token)
//         setToken(token);
//       }
//        catch (e) {
//         console.error(e);
//       }
//     })();
    
//   }, [getAccessTokenSilently]);


        const handleLogout = () => {
            logout({returnTo: `${frontendURL}`})
            // history.push("/")
        }

        return (
            <>
            <header className="header">
            <div className="page-brand">
                <a className="link" href="index.html">
                    <span className="brand">O
                        <span className="brand-tip">BI</span>
                    </span>
                    <span className="brand-mini">OBI</span>
                </a>
            </div>
            <div className="flexbox flex-1">
                {/* <!-- START TOP-LEFT TOOLBAR--> */}
                <ul className="nav navbar-toolbar">
                    {/* <li>
                        <a className="nav-link sidebar-toggler js-sidebar-toggler"><i className="ti-menu"></i></a>
                    </li> */
                    
                    }
                    {/* <li>
                        <form className="navbar-search" action="javascript:;">
                            <div className="rel">
                                <span className="search-icon"><i className="ti-search"></i></span>
                                <input className="form-control" placeholder="Search here..."/>
                            </div>
                        </form>
                    </li> */}
                </ul>
                {/* <!-- END TOP-LEFT TOOLBAR-->
                <!-- START TOP-RIGHT TOOLBAR--> */}
                <ul className="nav navbar-toolbar">
                    {
                        <li className="dropdown dropdown-inbox">
                        <a className="nav-link dropdown-toggle link" data-toggle="dropdown">
                            <span></span>{modalityTask}<i className="fa fa-angle-down m-l-5"></i></a>
                        <ul className="dropdown-menu dropdown-menu-right">
                            {
                                Object.entries(MODALITY_TASK).map(([key, value]) => {
                                return (
                                    <a className="dropdown-item" href="#" 
                                        onClick={(e) =>handleFilterChangeState(e, value)}>
                                        {value}
                                    </a>
                                    )
                                })
                            }
                        </ul>
                    </li>
                    
                    /*<li className="dropdown dropdown-inbox">
                        <a className="nav-link dropdown-toggle" data-toggle="dropdown"><i className="fa fa-envelope-o"></i>
                            <span className="badge badge-primary envelope-badge">9</span>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right dropdown-menu-media">
                            <li className="dropdown-menu-header">
                                <div>
                                    <span><strong>9 New</strong> Messages</span>
                                    <a className="pull-right" href="mailbox.html">view all</a>
                                </div>
                            </li>
                            <li className="list-group list-group-divider scroller" data-height="240px" data-color="#71808f">
                                <div>
                                    <a className="list-group-item">
                                        <div className="media">
                                            <div className="media-img">
                                                <img src="./assets/img/users/u1.jpg" />
                                            </div>
                                            <div className="media-body">
                                                <div className="font-strong"> </div>Jeanne Gonzalez<small className="text-muted float-right">Just now</small>
                                                <div className="font-13">Your proposal interested me.</div>
                                            </div>
                                        </div>
                                    </a>
                                    <a className="list-group-item">
                                        <div className="media">
                                            <div className="media-img">
                                                <img src="./assets/img/users/u2.jpg" />
                                            </div>
                                            <div className="media-body">
                                                <div className="font-strong"></div>Becky Brooks<small className="text-muted float-right">18 mins</small>
                                                <div className="font-13">Lorem Ipsum is simply.</div>
                                            </div>
                                        </div>
                                    </a>
                                    <a className="list-group-item">
                                        <div className="media">
                                            <div className="media-img">
                                                <img src="./assets/img/users/u3.jpg" />
                                            </div>
                                            <div className="media-body">
                                                <div className="font-strong"></div>Frank Cruz<small className="text-muted float-right">18 mins</small>
                                                <div className="font-13">Lorem Ipsum is simply.</div>
                                            </div>
                                        </div>
                                    </a>
                                    <a className="list-group-item">
                                        <div className="media">
                                            <div className="media-img">
                                                <img src="./assets/img/users/u4.jpg" />
                                            </div>
                                            <div className="media-body">
                                                <div className="font-strong"></div>Rose Pearson<small className="text-muted float-right">3 hrs</small>
                                                <div className="font-13">Lorem Ipsum is simply.</div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className="dropdown dropdown-notification">
                        <a className="nav-link dropdown-toggle" data-toggle="dropdown"><i className="fa fa-bell-o rel"><span className="notify-signal"></span></i></a>
                        <ul className="dropdown-menu dropdown-menu-right dropdown-menu-media">
                            <li className="dropdown-menu-header">
                                <div>
                                    <span><strong>5 New</strong> Notifications</span>
                                    <a className="pull-right" href="javascript:;">view all</a>
                                </div>
                            </li>
                            <li className="list-group list-group-divider scroller" data-height="240px" data-color="#71808f">
                                <div>
                                    <a className="list-group-item">
                                        <div className="media">
                                            <div className="media-img">
                                                <span className="badge badge-success badge-big"><i className="fa fa-check"></i></span>
                                            </div>
                                            <div className="media-body">
                                                <div className="font-13">4 task compiled</div><small className="text-muted">22 mins</small></div>
                                        </div>
                                    </a>
                                    <a className="list-group-item">
                                        <div className="media">
                                            <div className="media-img">
                                                <span className="badge badge-default badge-big"><i className="fa fa-shopping-basket"></i></span>
                                            </div>
                                            <div className="media-body">
                                                <div className="font-13">You have 12 new studies</div><small className="text-muted">40 mins</small></div>
                                        </div>
                                    </a>
                                    <a className="list-group-item">
                                        <div className="media">
                                            <div className="media-img">
                                                <span className="badge badge-danger badge-big"><i className="fa fa-bolt"></i></span>
                                            </div>
                                            <div className="media-body">
                                                <div className="font-13">Study #12 needs attention</div><small className="text-muted">2 hrs</small></div>
                                        </div>
                                    </a>
                                    <a className="list-group-item">
                                        <div className="media">
                                            <div className="media-img">
                                                <span className="badge badge-success badge-big"><i className="fa fa-user"></i></span>
                                            </div>
                                            <div className="media-body">
                                                <div className="font-13">New user registered</div><small className="text-muted">2 hrs</small></div>
                                        </div>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </li>*/}
                    
                   
                    
                    <li className="dropdown dropdown-user">
                        <a className="nav-link dropdown-toggle link" data-toggle="dropdown">
                            <img src="./assets/img/userLogo.png" />
                            <span></span>{user.name}<i className="fa fa-angle-down m-l-5"></i></a>
                        <ul className="dropdown-menu dropdown-menu-right">
                            {/* <a className="dropdown-item" href="profile.html"><i className="fa fa-user"></i>Profile</a>
                            <a className="dropdown-item" href="profile.html"><i className="fa fa-cog"></i>Settings</a>
                            <a className="dropdown-item" href="javascript:;"><i className="fa fa-support"></i>Support</a>
                            <li className="dropdown-divider"></li> */}
                            <a className="dropdown-item" href="#" onClick={() =>handleLogout()}><i className="fa fa-power-off"></i>Logout</a>
                        </ul>
                    </li>
                </ul>
                {/* </ul> */}
                {/* <!-- END TOP-RIGHT TOOLBAR--> */}
            </div>
        </header>
        </>
        )
    }


export default Header