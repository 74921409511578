import { REQUEST_METHOD } from "../common/constants";

import createNotification from "../components/notification/notificationComponent"

export default class Api {

    constructor() {
      this.URL = process.env.REACT_APP_BACKEND
      this.accessToken = null;
    }

    async getAccessToken() {
      if (this.accessToken == null) {
          this.accessToken = sessionStorage.getItem("Auth0token");
      }
      return this.accessToken;
    }

    async invokeService(uri, {method=REQUEST_METHOD.GET, params=null, data=null, no_response=false} = {}) {
        const accessToken = await this.getAccessToken();
        const options = {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        };
        let query_params = '';
        if (params != null) {
          const queryString = new URLSearchParams(params).toString();
          query_params = `?${queryString}`;
        }
        if (data != null) options.body = data;
        try {
          const response = await fetch(`${this.URL}/${uri}${query_params}`, options);
          if (response.status == 401) {
            createNotification('error', "Session expired, please login again");
          }
          if (no_response) {
            return {};
          }
          return response.json();
        } catch (e) {
          console.log("error", e);
          throw e;
        }
    }
}
