import React from "react";
import BasicLayout from '../../routes/basicLayout'
import ReactDataGridComponent from "./react_data_grid"
import JobCreationTab from "./jobCreationTab";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { withAuth0 } from '@auth0/auth0-react';
import { EVENTS, MODALITY_TASK } from "../../common/constants";
import eventBus from "../../common/eventBus";

const URL = process.env.REACT_APP_BACKEND

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    const filteredTabs = this.getFilteredTabs(MODALITY_TASK.Echo_Segmentation);
    const selected_tab = filteredTabs[0];
    this.state = {
      FilesData: null,
      thresholdview: null,
      key: selected_tab.key,
      filteredTabs: filteredTabs,
    }
  }

  tabs = [
    {
      key: "all", title: "All Files", view: ReactDataGridComponent, display_on: "all",
      predicted_view: [MODALITY_TASK.Echo_Segmentation]
    },
    {
      key: "downstream_task", title: "Files for Annotation", view: JobCreationTab,
      display_on: [MODALITY_TASK.Echo_Segmentation]
    },
    {
      key: "downstream_task", title: "Files for Classification", view: JobCreationTab, 
      display_on: [MODALITY_TASK.Echo_Classification, MODALITY_TASK.ECG_Classification]
    },
  ]

  getFilteredTabs(selected_modality) {
    return this.tabs.filter((tab) => {
      return (tab.display_on == 'all') ||
        tab.display_on.find((name) => name.toLowerCase() == selected_modality.toLowerCase()) != null
    });
  }

  handleSelect = (key) => {
    console.log('selected' + key);
    this.setState({ key: key });
  }

  onModalitySelected = (selected_modality) => {
    const filteredTabs = this.getFilteredTabs(selected_modality);
    this.setState({ filteredTabs });
  }

  async componentDidMount() {
    try {
      const { auth0 } = this.props;
      const token = await auth0.getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      //   scope: 'read:posts',
      });
      console.log("token",token)
      sessionStorage.setItem("Auth0token", token);
    } catch (e) {
      console.error(e);
    }
    eventBus.on(EVENTS.MODALITY_SELECTED, this.onModalitySelected);
  }

  componentWillUnmount() {
    eventBus.removeListener(EVENTS.MODALITY_SELECTED, this.onModalitySelected);
  }

  render() {
    return (
      <BasicLayout>
          <Tabs activeKey={this.state.key} onSelect={this.handleSelect} id="controlled-tab-example" >
              {
                this.state.filteredTabs.map((tab) => {
                  const TabComponent = tab.view;
                  return (
                  <Tab eventKey={tab.key} title={tab.title} key={tab.key}>
                    <TabComponent/>
                  </Tab>
                  )
                })
              }
          </Tabs>
      </BasicLayout>
    )
  }
}
export default withAuth0(Dashboard);