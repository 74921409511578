import React, { Component } from 'react'
import BasicLayout from '../../routes/basicLayout'


class Tables extends Component {
    render() {
        return (
            <BasicLayout>
            <div className="page-heading">
                <h1 className="page-title">Basic Tables</h1>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="index.html"><i className="la la-home font-20"></i></a>
                    </li>
                    <li className="breadcrumb-item">Basic Tables</li>
                </ol>
            </div>
            <div className="page-content fade-in-up">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">Basic Table</div>
                            </div>
                            <div className="ibox-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Username</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>@fat</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td>@twitter</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">Bordered Table</div>
                            </div>
                            <div className="ibox-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Username</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>@fat</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td>@twitter</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">Gray head</div>
                            </div>
                            <div className="ibox-body">
                                <table className="table">
                                    <thead className="thead-default">
                                        <tr>
                                            <th>#</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Username</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>@fat</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td>@twitter</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">Gray head</div>
                            </div>
                            <div className="ibox-body">
                                <table className="table table-bordered">
                                    <thead className="thead-default">
                                        <tr>
                                            <th>#</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Username</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>@fat</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td>@twitter</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">Striped rows</div>
                            </div>
                            <div className="ibox-body">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Username</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>@fat</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td>@twitter</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">Hover rows</div>
                            </div>
                            <div className="ibox-body">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Username</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>@fat</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td>@twitter</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ibox">
                    <div className="ibox-head">
                        <div className="ibox-title">Responsive Table</div>
                    </div>
                    <div className="ibox-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th width="50px"></th>
                                        <th>Product</th>
                                        <th>Price</th>
                                        <th>Data</th>
                                        <th>Last Name</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <label className="ui-checkbox">
                                                <input type="checkbox"/>
                                                <span className="input-span"></span>
                                            </label>
                                        </td>
                                        <td>iphone case</td>
                                        <td>$1200</td>
                                        <td>33%</td>
                                        <td>02/08/2017</td>
                                        <td>
                                            <button className="btn btn-default btn-xs m-r-5" data-toggle="tooltip" data-original-title="Edit"><i className="fa fa-pencil font-14"></i></button>
                                            <button className="btn btn-default btn-xs" data-toggle="tooltip" data-original-title="Delete"><i className="fa fa-trash font-14"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className="ui-checkbox">
                                                <input type="checkbox"/>
                                                <span className="input-span"></span>
                                            </label>
                                        </td>
                                        <td>Car covers</td>
                                        <td>$3280</td>
                                        <td>42%</td>
                                        <td>08/10/2017</td>
                                        <td>
                                            <button className="btn btn-default btn-xs m-r-5" data-toggle="tooltip" data-original-title="Edit"><i className="fa fa-pencil font-14"></i></button>
                                            <button className="btn btn-default btn-xs" data-toggle="tooltip" data-original-title="Delete"><i className="fa fa-trash font-14"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className="ui-checkbox">
                                                <input type="checkbox"/>
                                                <span className="input-span"></span>
                                            </label>
                                        </td>
                                        <td>Compressors</td>
                                        <td>$7400</td>
                                        <td>56%</td>
                                        <td>14/11/2017</td>
                                        <td>
                                            <button className="btn btn-default btn-xs m-r-5" data-toggle="tooltip" data-original-title="Edit"><i className="fa fa-pencil font-14"></i></button>
                                            <button className="btn btn-default btn-xs" data-toggle="tooltip" data-original-title="Delete"><i className="fa fa-trash font-14"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">Contextual classNamees</div>
                            </div>
                            <div className="ibox-body">
                                <table className="table table-hover">
                                    <caption>Optional table caption.</caption>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="active">
                                            <td>1</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>02/08/2017</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>04/10/2017</td>
                                        </tr>
                                        <tr className="info">
                                            <td>3</td>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td>08/11/2017</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>04/10/2017</td>
                                        </tr>
                                        <tr className="success">
                                            <td>3</td>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td>08/11/2017</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>04/10/2017</td>
                                        </tr>
                                        <tr className="warning">
                                            <td>3</td>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td>08/11/2017</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>04/10/2017</td>
                                        </tr>
                                        <tr className="danger">
                                            <td>3</td>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td>08/11/2017</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">Condensed Table</div>
                            </div>
                            <div className="ibox-body">
                                <table className="table table-condensed">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td><i className="fa fa-check text-success"></i></td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td><i className="fa fa-check text-success"></i></td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td><i className="fa fa-warning text-warning"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div>
                    <a className="adminca-banner" href="http://admincast.com/adminca/" target="_blank">
                        <div className="adminca-banner-ribbon"><i className="fa fa-trophy mr-2"></i>PREMIUM TEMPLATE</div>
                        <div className="wrap-1">
                            <div className="wrap-2">
                                <div>
                                    <img src="./assets/img/adminca-banner/adminca-preview.jpg" style="height:160px;margin-top:50px;" />
                                </div>
                                <div className="color-white" style="margin-left:40px;">
                                    <h1 className="font-bold">ADMINCA</h1>
                                    <p className="font-16">Save your time, choose the best</p>
                                    <ul className="list-unstyled">
                                        <li className="m-b-5"><i className="ti-check m-r-5"></i>High Quality Design</li>
                                        <li className="m-b-5"><i className="ti-check m-r-5"></i>Fully Customizable and Easy Code</li>
                                        <li className="m-b-5"><i className="ti-check m-r-5"></i>Bootstrap 4 and Angular 5+</li>
                                        <li className="m-b-5"><i className="ti-check m-r-5"></i>Best Build Tools: Gulp, SaSS, Pug...</li>
                                        <li><i className="ti-check m-r-5"></i>More layouts, pages, components</li>
                                    </ul>
                                </div>
                            </div>
                            <div style="flex:1;">
                                <div className="d-flex justify-content-end wrap-3">
                                    <div className="adminca-banner-b m-r-20">
                                        <img src="./assets/img/adminca-banner/bootstrap.png" style="width:40px;margin-right:10px;" />Bootstrap v4</div>
                                    <div className="adminca-banner-b m-r-10">
                                        <img src="./assets/img/adminca-banner/angular.png" style="width:35px;margin-right:10px;" />Angular v5+</div>
                                </div>
                                <div className="dev-img">
                                    <img src="./assets/img/adminca-banner/sprite.png" />
                                </div>
                            </div>
                        </div>
                    </a>
                </div> */}
            </div>
            </BasicLayout>
        )
        // return (
        //     <p>hi</p>
        // )
    }
}

export default Tables