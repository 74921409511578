import {NotificationContainer, NotificationManager} from 'react-notifications';
 
export default function createNotification  (type, Message)  {
      switch (type) {
        case 'info':
          NotificationManager.info(Message);
          break;
        case 'success':
          NotificationManager.success(Message, '',4000);
          break;
        case 'warning':
          NotificationManager.warning(Message, '', 4000);
          break;
        case 'error':
          NotificationManager.error(Message,'' ,4000 )
          break;
      }
    
  };
 