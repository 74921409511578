import React from "react";
import * as cornerstone from "cornerstone-core";
// @ts-ignore
import * as cornerstoneMath from "cornerstone-math";
 import * as cornerstoneTools from "cornerstone-tools";


import Hammer from "hammerjs";
// @ts-ignore
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import * as dicomParser from 'dicom-parser';
import Button from "react-bootstrap/Button"
import ButtonToolbar from "react-bootstrap/ButtonToolbar"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import { ProgressBar } from "react-bootstrap";
import { FaPlay, FaPause, FaStepForward, FaStepBackward, FaSortUp, FaSortDown, FaReadme} from 'react-icons/fa';
import {BsArrowsMove} from "react-icons/bs"
import { BiReset, BiZoomIn, BiZoomOut } from "react-icons/bi";
import {FiRotateCcw, FiRotateCw } from "react-icons/fi"
import {GiArrowCursor} from "react-icons/gi"
import ReactDOM from "react-dom";
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
toast.configure() ;



cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
//   cornerstoneWebImageLoader.external.cornerstone = cornerstone;
 cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneTools.external.Hammer = Hammer;


const divStyle = {
  width: "470px",
  height: "512px",
  color: "white",
  margin: "auto"
}

const bottomLeftStyle = {
  bottom: "5px",
  left: "5px",
  position: 'absolute',
  color: "black"
}

const bottomRightStyle = {
  bottom: "5px",
  right: "5px",
  position: 'absolute',
  color: "black"
}

const buttonControlStyle = {
    backgroundColor:"#A9A9A9",
    padding:"20px",
    alignItems:"center"
}

class CornerstoneElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        stack: props.stack,
        viewport: cornerstone.getDefaultViewport(null, undefined),
        imageId: props.stack.imageIds[0],
        progress: 100,
        isMultiframe: false,
        numOfFrames: 0,
        parsedData:{}
        };

        this.onImageRendered = this.onImageRendered.bind(this);
        this.onNewImage = this.onNewImage.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
    }
    // 
    render() {
     
        return (
            <>
            {/* https://fontawesome.com/icons/play?style=solid */}
               
                <div>
                    {/* <input type="file" id="selectFile" onChange={(e)=>{this.onImageUpload(e)}}></input> */}
                    { this.state.progress !== 100 && <ProgressBar variant="info" className="mb-1" animated style={{ 'height': '0.35rem' }} now={this.state.progress} />}
                    {/* <div>{this.state.stack["imageIds"]}</div>
                    <div>{this.state.stack["currentImageIdIndex"]}</div> */}
                      <ButtonToolbar aria-label="Toolbar with button groups">
                        <ButtonGroup className="col-md-12">
                            <Button style={buttonControlStyle} onClick={() => this.playDicom()} data-toggle="tooltip" title="Play"><FaPlay /></Button>
                            <Button style={buttonControlStyle} onClick={() => this.pauseDicom()} data-toggle="tooltip" title="Pause"><FaPause /></Button>
                            <Button style={buttonControlStyle} onClick={() => this.stepToPreviousFrame()} data-toggle="tooltip" title="Go to Previous Frame"><FaStepBackward /></Button>
                            <Button style={buttonControlStyle} onClick={() => this.stepToNextFrame()} data-toggle="tooltip" title="Go to Next Frame"><FaStepForward /></Button>
                            <Button style={buttonControlStyle} onClick={() => this.ZoomIn()} data-toggle="tooltip" title="Zoom"><BiZoomIn /></Button>
                            <Button style={buttonControlStyle} onClick={() => this.ZoomOut()} data-toggle="tooltip" title="Deactivate Zoom"><BiZoomOut /></Button>
                            {/* <Button style={buttonControlStyle} onClick={() => this.activatePan()} data-toggle="tooltip" title="Pan"><BsArrowsMove /></Button> */}
                            {/* <Button style={buttonControlStyle} onClick={() => this.deactivatePan()} data-toggle="tooltip" title="Deactivate Pan"><GiArrowCursor /></Button> */}
                            {/* <Button style={buttonControlStyle} onClick={() => this.increaseFps()} data-toggle="tooltip" title="Increase FPS"><FaSortUp /></Button>
                            <Button style={buttonControlStyle} onClick={() => this.decreaseFps()} data-toggle="tooltip" title="Decrease FPS"><FaSortDown /></Button> */}
                            {/* <Button style={buttonControlStyle} onClick={() => this.getMeasurementTools()} data-toggle="tooltip" title="Measurement Tools"><FaReadme /></Button> */}
                            <Button style={buttonControlStyle} onClick={() => this.rotateRight()} data-toggle="tooltip" title="Rotate Left"><FiRotateCw /></Button>
                            <Button style={buttonControlStyle} onClick={() => this.rotateLeft()} data-toggle="tooltip" title="Rotate Right"><FiRotateCcw /></Button>
                            <Button style={buttonControlStyle} onClick={() => this.resetImage()} data-toggle="tooltip" title="Reset to original Image"><BiReset /></Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                    
                    {/* <Button onClick={() => this.pauseDicom}>Split Screen</Button> */}
                    <div
                    className="viewportElement mb-2"
                    style={divStyle}
                    ref={input => {
                        this.element = input;
                    }}
                    >
                        
                    <canvas className="cornerstone-canvas" />
                    </div>
                    <div style={bottomLeftStyle}>Zoom: {this.state.viewport.scale || 1.0}</div>
                    <div style={bottomRightStyle}>
                    WW/WC: {this.state.viewport.voi.windowWidth} /{" "}
                    {this.state.viewport.voi.windowCenter}
                    </div>
                    {/* <div style={{marginLeft:"30px",padding:"30px"}}>
                        <label>Parsed Data</label>
                        <ul>
                            {Object.keys(this.state.parsedData).map(key => (
                            <li>
                            {key} : {this.state.parsedData[key]}
                            </li>))}
                        </ul>
                    </div> */}
                </div>
            </>
        );
    }

    
    // All Controls functions - start
    playDicom() {
        const element = this.element;
        cornerstoneTools.playClip(element, 10);
    }

    pauseDicom() {
        const element = this.element;
        cornerstoneTools.stopClip(element);
    }

     displayVideoMessage(message,seconds){
         toast.info(message,  
         {
           position: toast.POSITION.TOP_CENTER, autoClose:seconds
         })        
        
     }
    stepToNextFrame() {
        
        let nextImageId = this.state.stack["currentImageIdIndex"] + 1
        // console.log("current frame:" + nextImageId )
        
        if (nextImageId >= this.state.numOfFrames) {
          nextImageId = 0;

          this.displayVideoMessage(
            "You have reached the last frame of video",
            1500
          );
        } else {
          const element = this.element;

          let dicomFileName = this.state.imageId.split("?")[0];
          // Load the first image in the stack
          cornerstone
            .loadImage(`${dicomFileName}?frame=${nextImageId}`)
            .then((image) => {
                
            //   console.log(image);
              this.setState({
                imageId: `${dicomFileName}?frame=${nextImageId}`,
                stack: {
                  ...this.state.stack,
                  currentImageIdIndex: nextImageId,
                },
              });
              cornerstone.displayImage(element, image);
            });
        }
    }

    stepToPreviousFrame() {
        // console.log("NumofFrames:"+this.state.numOfFrames)
        let previousImageId = this.state.stack["currentImageIdIndex"] - 1
        // console.log("previousImageId=>", previousImageId)
        // console.log("previousImageId=>" + previousImageId)
        
        if(previousImageId <= 1)
           {
                previousImageId = 0
                this.displayVideoMessage("You have reached the on first frame of video",1500) 
              
            }else{
               // displayLabel.innerText="";
        const element = this.element;
        let dicomFileName = this.state.imageId.split('?')[0]
        // Load the first image in the stack
        cornerstone.loadImage(`${dicomFileName}?frame=${previousImageId}`).then(image => {
            // console.log(image)
            this.setState({
                imageId : `${dicomFileName}?frame=${previousImageId}`,
                stack: {
                    ...this.state.stack,
                    "currentImageIdIndex": previousImageId
                }
            })
            cornerstone.displayImage(element, image);
        })
            }
    }

    ZoomIn() {
        const viewport = cornerstone.getViewport(this.element);
        viewport.scale += 0.25;
        cornerstone.setViewport(this.element, viewport);
    }

    ZoomOut() {
        const viewport = cornerstone.getViewport(this.element);
        viewport.scale -= 0.25;
        cornerstone.setViewport(this.element, viewport);
    }

    activatePan() {
        const element = this.element;
        cornerstoneTools.pan.activate(element, 1)
    }

    deactivatePan() {
        const element = this.element;
        cornerstoneTools.pan.deactivate(element)
    }

    increaseFps() {

    }

    decreaseFps() {

    }

    getMeasurementTools() {
        const element = this.element;
        cornerstoneTools.length.activate(element, 1)
    }

    rotateLeft() {
        const viewport = cornerstone.getViewport(this.element);
        viewport.rotation-=90;
        cornerstone.setViewport(this.element, viewport);
    }

    rotateRight() {
        const viewport = cornerstone.getViewport(this.element);
        viewport.rotation+=90;
        cornerstone.setViewport(this.element, viewport);
    }
    
    resetImage() {
        const element = this.element;
        cornerstone.reset(element);
    }
    
    // All Controls functions - end

    onWindowResize() {
        cornerstone.resize(this.element);
    }

    onImageRendered() {
        const viewport = cornerstone.getViewport(this.element);
        this.setState({
            viewport
        });
    }

    onNewImage() {
       
       
        
      if(  this.state.stack["currentImageIdIndex"] < this.state.numOfFrames-1){
        var enabledElement = cornerstone.getEnabledElement(this.element);
        cornerstone.resize(this.element);
        
        this.setState({
            imageId: enabledElement&& enabledElement.image&& enabledElement.image.imageId
        });
    }
    else{
        cornerstoneTools.stopClip(this.element);
    }
           
        
        
    
    
    
    }

    componentDidMount() {
        
        const element = this.element;
        // Enable the DOM Element for use with Cornerstone
        cornerstone.enable(element);

        cornerstone.events.addEventListener('cornerstoneimageloadprogress', (event) => {
            const eventData = event.detail;
            this.setState({
            ...this.state,
            progress: eventData.percentComplete
            })
        });
        this.loadImage();

    }

  componentDidUpdate() {
    const element = this.element;
    // Enable the DOM Element for use with Cornerstone
    cornerstone.enable(element);

    cornerstone.events.addEventListener('cornerstoneimageloadprogress', (event) => {
      const eventData = event.detail;
      this.setState({
        ...this.state,
        progress: eventData.percentComplete
      })
    });
    this.loadImage();

  }

//   onImageUpload(e) {
//         // Add the file to the cornerstoneFileImageLoader and get unique
//         // number for that file
//         const file = e.target.files[0];
//         console.log("file=>", file)
        
//         const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file1);
//         this.setState({'imageId':imageId})
//         // loadAndViewImage(imageId);
//         let that=this;
//         setTimeout(function(){ that.loadImage(); }, 3000);
//   }

    getParsedDataFromImage(imageData) {
        let parsedData = {}
        parsedData['patientId'] = imageData.string('x00100020')
        parsedData['studyId'] = imageData.string('x00200010')
        parsedData['studyInstanceId'] = imageData.string('x0020000d')
        parsedData['instanceNumber'] = imageData.string('x00200013')
        parsedData['patientName'] = imageData.string('x00100010')
        parsedData['patientAge'] = imageData.string('x00101010')
        parsedData['patientBirthDate'] = imageData.string('x00100030')
        parsedData['patientSex'] = imageData.string('x00100040')
        parsedData['StudyDescription'] = imageData.string('x00081030')
        parsedData['studyDate'] = imageData.string('x00080020')
        parsedData['studyTime'] = imageData.string('x00080030')


       
        // parsedData['studyVerifiedDate'] = imageData.string('x00320032')
        // parsedData['studyVerifiedTime'] = imageData.string('x00320033')
        //   let noOfFrames = (0028,0008)
        // let patientId = (0010,0020)
        // let patientName = (0010,0010)
        // let patientAge = (0010,1010)
        // let patientBirthDate = (0010,0030) 
        // let patientSex = (0010,0040)
        // let StudyDescription = (0008, 1030)
        // let studyInstanceId = (0020, 000d)
        // let studyDate = (0008,0020)
        // let studyId = (0020,0010)
        // let studyTime = (0008,0030)
        // let studyVerifiedDate = (0032,0032)
        // let studyVerifiedTime = (0032,0033)
        return parsedData
    }

    loadImage() {
        
        const element = this.element;
        
        // Load the first image in the stack
        //  cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.load(this.state.imageId, cornerstoneWADOImageLoader.internal.xhrRequest).then(function(image) {
            cornerstone.loadImage(this.state.imageId).then(image => {
          
          if(this.element != null){
            cornerstone.displayImage(element, image);  console.log(image)
          let frames = image.data.elements.x7fe00010.fragments.length
            let parsedData = this.getParsedDataFromImage(image.data)
            // console.log(parsedData)
            this.setState({'parsedData':parsedData})
        
          
        if (!frames) {
            this.setState({ ...this.state, isMultiframe: false, numOfFrames: 0 })

            // Display the first image
            cornerstone.displayImage(element, image);
            cornerstone.resize(this.element);
            // const viewport = cornerstone.getViewport(this.element);
            // Add the stack tool state to the enabled element
            const stack = this.props.stack;
            cornerstoneTools.addStackStateManager(element, ["stack"]);
            cornerstoneTools.addToolState(element, "stack", stack);
            
        }
        else {
            
            const newSetImageIds = [...Array(+frames)].map((e, i) => { return this.state.imageId + "?frame=" + i });
            // console.log("newSetImageIds=>", newSetImageIds)
            this.setState({
            ...this.state, isMultiframe: true, numOfFrames: +
                frames, stack: { ...this.state.stack, imageIds: newSetImageIds }
            })

            cornerstone.displayImage(element, image);
            // Set the stack as tool state
            cornerstoneTools.addStackStateManager(element, ['stack', 'playClip']);
            cornerstoneTools.addToolState(element, 'stack', this.state.stack);
            // Start playing the clip
            // TODO: extract the frame rate from the dataset
            
            var frameRate = 10;
            cornerstoneTools.playClip(element, frameRate);
        }
        element.addEventListener(
            "cornerstoneimagerendered",
            this.onImageRendered
        );
      
            element.addEventListener("cornerstonenewimage", this.onNewImage);
        
        window.addEventListener("resize", this.onWindowResize);
        }
        });
    }

    componentWillUnmount() {
        
        const element = this.element;

        cornerstoneTools.stopClip(element);
        // cornerstoneTools.mouseInput.disable(element);
        element.removeEventListener(
        "cornerstoneimagerendered",
        this.onImageRendered
        );

        element.removeEventListener("cornerstonenewimage", this.onNewImage);

        window.removeEventListener("resize", this.onWindowResize);
        cornerstone.events.removeEventListener('cornerstoneimageloadprogress')
        cornerstone.disable(element);
    }
    componentDidUpdate(prevProps, prevState) {
        
      const stackData = cornerstoneTools.getToolState(this.element, "stack");
    //   console.log(stackData);    
      const stack = stackData?.data[0];
      if(stack){
        stack.currentImageIdIndex = this.state.stack?.currentImageIdIndex;
        stack.imageIds = this.state.stack?.imageIds;
        cornerstoneTools.addToolState(this.element, "stack", stack);
      }
    //   const imageId = stack.imageIds[stack.currentImageIdIndex];
    //   cornerstoneTools.scrollToIndex(this.element, stack.currentImageIdIndex);
    }
    }   



// render(<App />, document.getElementById("root"));
export default CornerstoneElement
